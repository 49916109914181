import React, { useState } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import {
  AdminSharedHeader,
  AdminSharedTable,
  AdminSharedDeleteModal,
  TableDropDownView,
} from "../Shared/";
import { BackendForm } from "./Form";
import { useQuery, useDelete } from "../../../hooks";
import { useGetBackendsQuery, useDeleteBackendMutation } from "../services";
import { TickOrCross } from "../../Shared";
import { Link } from "react-router-dom";

export const BackendsContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_backend_type_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "backends.created_at",
    order_dir: "desc",
  };

  let query = useQuery();
  let filterLabel = "";
  const filters = [{ label: "Client", key: "client_id" }];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterLabel = `${filter.label} : ${query.get("name")}`;
    }
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetBackendsQuery({
    ...queryParams,
    q,
  });

  const {
    deleteItem: deleteBackend,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteBackendMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const headers = [
    { label: "Name", key: "name", className: "name" },
    { label: "Type", key: "backend_type", className: "type" },
    { label: "Forwarding", key: "forwarding_option", className: "forwarding" },
    { label: "Protocol", key: "protocol", className: "protocol" },
    { label: "Host", key: "host", className: "host" },
    { label: "Path/Topic", key: "path", className: "path" },
    { label: "Port", key: "port", className: "port" },
    { label: "Timeout", key: "timeout", className: "timeout text-center" },
    { label: "Max Retry", key: "maximum_retry_count", className: "max-reconnect text-center" },
    {
      label: "Enable",
      nestedValue: true,
      getNestedValue: ({ enabled }) => <TickOrCross flag={enabled} />,
      className: "status",
    },
    {
      label: "Clients",
      type: "component",
      component: ({ data }) => {
        const clientNames = data?.client_names || [""];
        return <TableDropDownView data={clientNames} />;
      },
      className: "clients more",
    },
    {
      label: "Devices",
      nestedValue: true,
      className: "devices text-center",
      getNestedValue: ({ id, devices_count, name }) => {
        return (
          <>
            {devices_count > 0 ? (
              <Link
                to={`/superadmin/devices/list?backend_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {devices_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }

    return name;
  };

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          groupName="other"
          heading="Configurations"
          handleSearchKey={(value) => setSimpleSearch(value)}
          simpleSearchPlaceHolder="Search by Name, Type"
          filterText={filterLabel && `Filtered by ${filterLabel}`}
        />
        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          headers={headers}
          data={data.data}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          onDelete={deleteClickHandler}
          className="backends"
          auditResource="Backend"
          auditKey="name"
        />
      </article>

      {showForm && <BackendForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}

      <AdminSharedDeleteModal
        show={showDeleteWarning}
        resourceName="backend"
        error={deleteErrorMsg}
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg();
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteBackend({ id: idToDelete })}
      />
    </>
  );
};
