import React, { useState } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { MPSharedHeader, MPSharedTable, MPSharedDeleteModal } from "../Shared/";
import { BackendForm } from "./Form";
import { useDelete } from "../../../hooks";
import { useGetBackendsQuery, useDeleteBackendMutation } from "../services";
import { TickOrCross } from "../../Shared";
import { Link } from "react-router-dom";
import {getLocalizedString} from "../../../shared/translation";

export const BackendsContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_backend_type_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetBackendsQuery({
    page,
    per_page: perPage,
    order_by: "backends.created_at",
    order_dir: "desc",
    q,
  });

  const {
    deleteItem: deleteBackend,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteBackendMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const headers = [
    { label: getLocalizedString("name", "Name"), key: "name", className: "name" },
    { label: getLocalizedString("type", "Type"), key: "backend_type", className: "type" },
    {
      label: getLocalizedString("forwarding", "Forwarding"),
      key: "forwarding_option",
      className: "forwarding",
    },
    {
      label: getLocalizedString("protocol", "Protocol"),
      key: "protocol",
      className: "protocol",
    },
    { label: getLocalizedString("host", "Host"), key: "host", className: "host" },
    {
      label: getLocalizedString("path/topic", "Path/Topic"),
      key: "path",
      className: "path",
    },
    { label: getLocalizedString("port", "Port"), key: "port", className: "port" },
    {
      label: getLocalizedString("timeout", "Timeout"),
      key: "timeout",
      className: "timeout text-center",
    },
    {
      label: getLocalizedString("max_retry", "Max Retry"),
      key: "maximum_retry_count",
      className: "max-reconnect text-center",
    },
    {
      label: getLocalizedString("enable", "Enable"),
      nestedValue: true,
      getNestedValue: ({ enabled }) => <TickOrCross flag={enabled} />,
      className: "status",
    },
    {
      label: getLocalizedString("default", "Default"),
      nestedValue: true,
      getNestedValue: ({ default: isDefault }) => <TickOrCross flag={isDefault} />,
      className: "status",
    },
    {
      label: getLocalizedString("devices", "Devices"),
      nestedValue: true,
      className: "devices text-center",
      getNestedValue: ({ id, devices_count, name }) => {
        return (
          <>
            {devices_count > 0 ? (
              <Link
                to={`/management_portal/devices/list?backend_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {devices_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    {
      label: getLocalizedString("added_on", "Added On"),
      key: "created_at",
      className: "date",
    },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName="configurations"
          resourceName="Backends"
          createNewHandler={createNewHandler}
          heading={getLocalizedString("configurations", "Configurations")}
          handleSearchKey={(value) => setSimpleSearch(value)}
          onEdit={editClickHandler}
          simpleSearchPlaceholder={
            getLocalizedString("search_by_name_type", "Search by Name, Type")
          }
        />
        <MPSharedTable
          resourceName="Backends"
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          headers={headers}
          data={data.data}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          onDelete={deleteClickHandler}
          className="backends"
          auditResource="Backend"
          auditKey="name"
          skipClientId
        />
      </article>

      {showForm && <BackendForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        error={deleteErrorMsg}
        entityName={getLocalizedString("backend_delete", "backend")}
        itemName={getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteBackend({ id: idToDelete })}
      />
    </>
  );
};
