import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, NavLink } from "react-router-dom";
import { QIButton, QISimpleSearch } from "../../../components";
import sprite from "../../../assets/icons.svg";
import { SvgIcon } from "../../Shared";

let timer;
export const AdminSharedHeader = ({
  createNewHandler,
  groupName,
  heading,
  headingPresent = true,
  handleAdvanceSearchKeys,
  handleSearchKey,
  filterText = "",
  simpleSearchPlaceHolder = "",
  defaultSearchType = "simple",
  beforeSearchDom = null,
  className = "",
  children,
  uploadHandler,
  customHandler = () => {},
}) => {
  const [searchParams] = useSearchParams();
  const paramsSearch = searchParams.get("q");
  const [searchKey, setSearchKey] = useState(paramsSearch || "");
  const [searchType, setSearchType] = useState(defaultSearchType);

  const deviceHeaders = [
    {
      label: "Devices",
      path: "devices/list",
    },
    {
      label: "Models",
      path: "devices/models",
    },
    {
      label: "Manufacturers",
      path: "devices/manufacturers",
    },
  ];

  const vehicleHeaders = [
    {
      label: "Vehicles",
      path: "vehicles/list",
    },
    {
      label: "Specifications",
      path: "vehicles/specifications",
    },
    {
      label: "Variants",
      path: "vehicles/variants",
    },
    {
      label: "Models",
      path: "vehicles/manufacturer_models",
    },
    {
      label: "Manufacturers",
      path: "vehicles/manufacturers",
    },
    {
      label: "Classes",
      path: "vehicles/classes",
    },
    {
      label: "Types",
      path: "vehicles/types",
    },
  ];

  const otherHeaders = [
    {
      label: "Variables",
      path: "others/variables",
    },
    {
      label: "Variable Mappings",
      path: "others/variable_mappings",
    },
    {
      label: "Backends",
      path: "others/backends",
    },
    {
      label: "Geocoders",
      path: "others/geocoders",
    },
    {
      label: "Geolocation Plans",
      path: "others/geolocation_plan",
    },
    {
      label: "OBD Codes",
      path: "others/obd_codes",
    },
    {
      label: "Batch Commands",
      path: "others/batch_command",
    },
    {
      label: "Mobile Service Providers",
      path: "others/msp",
    },
    {
      label: "Localizations",
      path: "others/localization",
    },
  ];

  const headers = () => {
    switch (groupName) {
      case "device":
        return deviceHeaders;
      case "vehicle":
        return vehicleHeaders;
      default:
        return otherHeaders;
    }
  };

  const handleSearchChange = (value) => {
    setSearchKey(value);
    clearTimeout(timer);
    // Debouncing for 500 seconds
    timer = setTimeout(() => {
      handleSearchKey(value);
    }, 500);
  };

  const handleSearchSwitch = () => {
    if (searchType === "simple") {
      handleSearchKey("");
      setSearchType("advance");
    } else {
      handleAdvanceSearchKeys(null);
      setSearchType("simple");
    }
  };

  useEffect(() => {
    let query = searchParams.get("q");

    if (query != searchKey) {
      setSearchKey(query ? query : "");
    }
  }, [searchParams]);

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  // Horizontal scroll for responsive UI
  const elementRef = useRef(null);

  // Visibility for left arrow
  const [arrowVisibleLeft, setArrowVisibleLeft] = useState(true);

  // Visibility for right arrow
  const [arrowVisibleRight, setArrowVisibleRight] = useState(false);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;

    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);

      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowVisibleLeft(true);
      } else {
        setArrowVisibleLeft(false);
      }
      if (
        Math.trunc(element.scrollWidth - element.clientWidth) === Math.trunc(element.scrollLeft)
      ) {
        setArrowVisibleRight(true);
      } else {
        setArrowVisibleRight(false);
      }
    }, speed);
  };

  return (
    <>
      {headingPresent && (
        <header className={`main-container_header ${className}`}>
          <div className="main-container_header_title-and-search-group">
            <h3 className="page-title xl">{heading}</h3>
            {beforeSearchDom}
            {searchType === "simple" && handleSearchKey && (
              <QISimpleSearch
                searchKey={searchKey}
                handleSearchChange={handleSearchChange}
                handleAdvanceSearchKeys={handleAdvanceSearchKeys}
                handleSearchSwitch={handleSearchSwitch}
                simpleSearchPlaceholder={simpleSearchPlaceHolder}
              />
            )}

            {searchType === "advance" && (
              <div className="search-form-container">
                {defaultSearchType == "simple" && (
                  <SvgIcon
                    wrapperClass="back-icon clickable"
                    svgClass="icon-back"
                    name="back"
                    onClick={handleSearchSwitch}
                  />
                )}
                {children}
              </div>
            )}
          </div>

          <div className="header-buttons-wrapper">
            {uploadHandler && (
              <QIButton
                className="qi-btn primary md"
                icon={{ src: `${sprite}#upload`, className: "upload-icon" }}
                onClick={uploadHandler}
              >
                Import
              </QIButton>
            )}

            {customHandler()}

            {createNewHandler && (
              <QIButton
                className="qi-btn primary md"
                icon={{ src: `${sprite}#add`, className: "add-icon" }}
                onClick={createNewHandler}
              >
                Add New
              </QIButton>
            )}
          </div>
        </header>
      )}

      {groupName && (
        <aside className="main-container_secondary-header">
          <nav className="main-container_header_link">
            <span className="tab-slider">
              {!arrowVisibleLeft && (
                <div className="slide">
                  <SvgIcon
                    name="up-arrow"
                    wrapperClass="previous"
                    onClick={() => {
                      handleHorizantalScroll(elementRef.current, 25, 100, -10);
                    }}
                  />
                </div>
              )}
            </span>

            <div class="scroll-wrapper" ref={elementRef}>
              {headers().map((link, index) => (
                <NavLink
                  to={`/superadmin/${link.path}` || ""}
                  className="main-container_header_link_item"
                  key={index}
                >
                  <strong title={link.label} className="mx-1">
                    {link.label}
                  </strong>
                </NavLink>
              ))}
            </div>

            <span className="tab-slider">
              {!arrowVisibleRight && (
                <div className="slide">
                  <SvgIcon
                    name="up-arrow"
                    wrapperClass="previous"
                    svgClass="rotate-180"
                    onClick={() => {
                      handleHorizantalScroll(elementRef.current, 25, 100, 10);
                    }}
                  />
                </div>
              )}
            </span>
          </nav>
          {filterText && (
            <p title={filterText} className="showing-shortlisted">
              {filterText}
            </p>
          )}
        </aside>
      )}
    </>
  );
};
