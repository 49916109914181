import React, { useState, useEffect } from "react";
import {
  AdminSharedHeader,
  AdminSharedTable,
  AdminSharedDeleteModal,
  TableDropDownView,
} from "../Shared/";
import { useSearchQuery, usePagination } from "../../../hooks";
import { useGetDevicesQuery, useDeleteDeviceMutation } from "../services";
import { DeviceAdvanceSearch } from "./advanceSearch";
import { DeviceForm } from "./Form";
import { CommandSending } from "./commandSending";
import { useQuery, useDelete } from "../../../hooks";
import { TickOrCross, SvgIcon } from "../../Shared";
import { useGetDevicesTrackingQuery } from "../../Tracking/services";
import moment from "moment";

export const DevicesContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();
  const { setSimpleSearch, advanceSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_device_id_or_filter_device_ph_with_country_code_cont",
  });

  const [selectedDevice, setSelectedDevice] = useState("");
  const [sourceIds, setSourceIds] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "devices.created_at",
    order_dir: "desc",
  };

  const query = useQuery();
  let filterHeader = "";
  const filters = [
    { label: " manufacturer: ", key: "device_manufacturer_id" },
    { label: " model:", key: "device_model_id" },
    { label: " model:", key: "geocoder_id" },
    { label: " client:", key: "client_id" },
    { label: "variable mapping:", key: "variable_mapping_id" },
    { label: " backend:", key: "backend_id" },
  ];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterHeader = `${filter.label} ${query.get("name")}`;
    }
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetDevicesQuery({ ...queryParams, q });

  //Collect Source Ids
  useEffect(() => {
    if (data?.data.length > 0) {
      setSourceIds(data?.data?.map((devices) => devices?.device_id));
    }
  }, [data]);

  //Get Latest Data
  const {
    data: trackingData = { Tdata: [] },
    error: trackingError,
    isLoading: isLoadingTracking,
  } = useGetDevicesTrackingQuery(
    sourceIds && {
      device_ids: sourceIds,
      // fields: "device_data.source_id,device_data.gps,device_data.velocity,device_data.ignition",
    },
    { refetchOnMountOrArgChange: true }
  );

  /**
   * This effect updates the combined data with the latest tracking information,
   * if both device data and tracking data are available.
   */

  useEffect(() => {
    // Check if there is device data and tracking data available
    if (data?.data?.length > 0 && trackingData?.latest_data?.length > 0) {
      // Map through each device to find matching tracking info
      const newData = data?.data?.map((device) => {
        const matchingTrackingInfo = trackingData?.latest_data?.find(
          (tracking) => tracking?.device_data?.source_id === device?.device_id
        );

        // If matching tracking info is found, update server_time in device data
        if (matchingTrackingInfo) {
          return {
            ...device,
            server_time:
              matchingTrackingInfo?.device_data?.server_time ||
              matchingTrackingInfo?.device_reply?.server_time ||
              matchingTrackingInfo?.heart_beat?.server_time,
          };
        } else {
          // If no matching tracking info, return the original device data
          return device;
        }
      });

      // Update the combined data with the new information
      setCombinedData(newData);
    } else {
      //If no tracking data, then use the previous data
      setCombinedData(data?.data);
    }
  }, [data, trackingData]);

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const {
    deleteItem: deleteDevice,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteDeviceMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  /**
   * Handler for edit button click.
   * @param {Object} datum - Data of the device being edited.
   * @returns {void}
   */

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  /**
   * Data headers for the device table.
   * @type {Array<Object>}
   */

  const headers = [
    { label: "Client", key: "client_name", className: "client" },
    {
      label: "Name",
      nestedValue: true,
      className: "name",
      getNestedValue: ({ device_manufacturer_name, device_model_name, name }) =>
        `${name}  (${device_manufacturer_name} - ${device_model_name})`,
    },
    { label: "ID/IMEI", key: "device_id", className: "id" },
    {
      label: "Last Received",
      className: "id",
      nestedValue: true,
      getNestedValue: ({ server_time }) => {
        if (!trackingError) {
          return `${server_time ? moment(server_time).format("DD/MM/YYYY - hh:mm:ss A") : ""}`;
        } else {
          return "Unable to Fetch - Error occurred";
        }
      },
    },
    /* { label: "SIM card No", key: "simcard_no", className: "sim-number" }, */
    {
      label: "Phone No",
      nestedValue: true,
      getNestedValue: (element) =>
        `${element.country_code ? `${element.country_code}-` : ""}${
          element.simcard_phone_no || ""
        }`,
      className: "phone-number",
    },
    { label: "Vehicle Plate No.", key: "plate_number", className: "plate-number" },
    {
      label: "Enabled",
      nestedValue: true,
      className: "status",
      getNestedValue: ({ enabled }) => <TickOrCross flag={enabled} />,
    },
    {
      label: "Backends",
      type: "component",
      className: "backends more",
      component: ({ data }) => {
        const backends = data?.backend_names?.split(",") || [""];
        return <TableDropDownView data={backends} />;
      },
    },
    { label: "Geolocation Plan", key: "geolocation_plan_name", className: "text" },
    { label: "Firmware", key: "firmware_version", className: "firmware" },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  const additionalActions = [
    {
      component: (data) => (
        <SvgIcon
          wrapperClass="clickable"
          name="console"
          onClick={() => setSelectedDevice(data)}
          title="Terminal"
        />
      ),
    },
  ];

  /**
   * Gets the name of the item to be deleted.
   * @param {number} id - The ID of the item to be deleted.
   * @returns {string} - The name of the item to be deleted.
   */

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }
    return name;
  };

  /**
   * Determines the parent name based on query parameters.
   * @returns {string} - The parent name.
   */

  const parentName = () => {
    if (queryParams.device_manufacturer_id) {
      return data.data[0]?.device_manufacturer_name;
    } else if (queryParams.device_model_id) {
      return data.data[0]?.device_model_name;
    } else if (queryParams.client_id) {
      return data.data[0]?.client_name;
    } else if (queryParams.geocoder_id) {
      return data.data[0]?.geocoder_names;
    } else if (queryParams.backend_id) {
      return data.data[0]?.backend_id;
    }
  };

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          groupName="device"
          heading="Manage Devices"
          handleSearchKey={(value) => setSimpleSearch(value)}
          handleAdvanceSearchKeys={(value) => setAdvanceSearch(null)}
          filterText={filterHeader && `Filtered by ${filterHeader}`}
          simpleSearchPlaceHolder="Search by Name, Imei, Phone No."
        >
          <DeviceAdvanceSearch
            onSearch={(value) => setAdvanceSearch(value)}
            activeSearch={advanceSearch}
          />
        </AdminSharedHeader>

        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={combinedData || []}
          headers={headers}
          onEdit={editClickHandler}
          pagination={{ ...pagination, count: data.total_count }}
          parentName={parentName()}
          className="devices"
          additionalActions={additionalActions}
          auditResource="Device"
          auditKey="device_id"
        />
      </article>
      <AdminSharedDeleteModal
        error={deleteErrorMsg}
        show={showDeleteWarning}
        resourceName="device"
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteDevice({ id: idToDelete })}
      />
      {showForm && <DeviceForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}
      {selectedDevice && (
        <CommandSending deviceData={selectedDevice} onClose={() => setSelectedDevice(null)} />
      )}
    </>
  );
};
