import { useEffect, useState } from "react";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { QISelect, QIButton } from "../../../components";
import { removeDuplicatesAndSort } from "../../Admin/utils";
import {getLocalizedString} from "../../../shared/translation";

/**
 * A React component representing a terminal to display real-time messages.
 *
 * @param {string} imei - The IMEI identifier.
 * @param {boolean} show - Whether to show the terminal or not.
 * @returns {JSX.Element} A React JSX element representing the Terminal.
 */

export const Terminal = ({ imei, show }) => {
  const [type, setType] = useState("ALL");
  const [connection, setConnection] = useState(false);
  const [terminalMsg, setTerminalMsg] = useState([]);

  const types = [
    {
      label: "All",
      method: "sub_all",
      key: "ALL",
    },
    {
      label: "Position",
      method: "sub_gps_location",
      key: "POSITION",
    },
    {
      label: "Events",
      method: "sub_events",
      key: "EVENTS",
    },
    {
      label: "Velocity",
      method: "sub_velocity",
      key: "VELOCITY",
    },
    {
      label: "Mileage",
      method: "sub_mileage",
      key: "MILEAGE",
    },
    {
      label: "Forwarder Logs",
      method: "sub_forwarder_log",
      key: "FORWARDER_LOGS",
    },
  ];

  /**
   * Adds a message object to the terminal message queue.
   *
   * @param {Object} messageObject - The message object to add.
   */

  const addToMsgQueue = (messageObject) => {
    setTerminalMsg((prevState) => [messageObject, ...prevState]);
  };

  useEffect(() => {
    const terminalsocketconnection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_PUBLISHER_URL)
      .withAutomaticReconnect()
      .build();

    setConnection(terminalsocketconnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection.serverTimeoutInMilliseconds = 5 * 60 * 1000;

      types.forEach((type) => {
        connection.on(type.method, (message) => {
          const jsonMessage = JSON.parse(message);
          if(jsonMessage.PacketType) // Handle Device Packets
          {
            addToMsgQueue({
              headertext: jsonMessage.PacketType,
              datetimetext:
                jsonMessage.PacketTime > 0
                  ? moment(jsonMessage.PacketTime).format("DD-MM-YYYY HH:mm:ss A")
                  : moment().format("DD-MM-YYYY HH:mm:ss A"),
              displaytext: JSON.stringify(jsonMessage.Data, null, 2),
            });
          }
          else if(jsonMessage.BackendId) // Handle Backend Forwarder Logs
          {
            addToMsgQueue({
              headertext: jsonMessage.PacketId,
              datetimetext:
                jsonMessage.LogMessageTime > 0
                  ? moment(jsonMessage.LogMessageTime).format("DD-MM-YYYY HH:mm:ss A")
                  : moment().format("DD-MM-YYYY HH:mm:ss A"),
              displaytext: jsonMessage.LogMessage,
            });
          }
          else // Unknown Messages
          {
            addToMsgQueue({
              datetimetext: moment().format("DD-MM-YYYY HH:mm:ss A"),
              displaytext: message,
            });
          }
        });
      });
      connection.start();
    }
  }, [connection]);

  useEffect(() => {
    return unsubscribeChannel;
  }, []);

  const handleSubscription = async () => {
    try {
      addToMsgQueue({
        headerText: type && `${getLocalizedString("subscribed", "Subscribed")} ${type}`,
        datetimetext: moment().format("DD-MM-YYYY HH:mm:ss A"),
        displaytext: "",
      });

      await connection.invoke("Subscribe", [imei], type);
    } catch (e) {
      addToMsgQueue({
        headerText: getLocalizedString("connection_failed", "Connection Failed"),
        datetimetext: moment().format("DD-MM-YYYY HH:mm:ss A"),
        displaytext: e.toString(),
      });
    }
  };

  const unsubscribeChannel = async () => {
    try {
      addToMsgQueue({
        headerText: type && `${getLocalizedString("unsubscribed", "Unsubscribed")} ${type}`,
        datetimetext: moment().format("DD-MM-YYYY HH:mm:ss A"),
        displaytext: "",
      });
      await connection.invoke("UnSubscribe", [imei], type);
      await connection.stop();
    } catch (e) {
      addToMsgQueue({
        headerText: getLocalizedString("connection_failed", "Connection Failed"),
        datetimetext: moment().format("DD-MM-YYYY HH:mm:ss A"),
        displaytext: e.toString(),
      });
    }
  };

  /**
   * Formats mapping hash for display.
   *
   * @param {string} mappingHash - The mapping hash to format.
   * @returns {JSX.Element|string} A formatted JSX element or the original string.
   */

  const formatMapping = (mappingHash) => {
    let formatString;

    try {
      JSON.parse(mappingHash);

      formatString = (
        <code>
          <pre className="code-format">{mappingHash}</pre>
        </code>
      );
    } catch (e) {
      formatString = mappingHash;
    }

    return formatString;
  };

  return (
    <>
      <div className={`flex my-3`}>
        <QISelect className="mr-2" value={type} onChange={(e) => setType(e.target.value)}>
          {types.map((type) => (
            <option key={type.key} value={type.key}>
              {type.label}
            </option>
          ))}
        </QISelect>

        <QIButton
          className="qi-btn qi-btn primary sm mx-3"
          onClick={handleSubscription}
          disabled={!connection._connectionState === "Connected"}
        >
          {getLocalizedString("subscribe", "Subscribe")}
        </QIButton>
        <QIButton
          className="qi-btn qi-btn secondary sm"
          onClick={unsubscribeChannel}
          disabled={!connection._connectionState === "Connected"}
        >
          {getLocalizedString("unsubscribe", "Unsubscribe")}
        </QIButton>
      </div>

      {removeDuplicatesAndSort(terminalMsg)?.length>0 &&
        <Scrollbars autoHeight autoHeightMax="calc(100vh - 380px)">
          <ul className="qi-list-view_list terminal-response">
            {removeDuplicatesAndSort(terminalMsg).map((msg, index) => (
              <li className="qi-list-view_list_item terminal-response_item" key={index}>
                <div className="response-and-date">
                  <strong className="terminal-response_column response-for">{msg.headerText}</strong>
                  <span className="terminal-response_column date-time-stamp">{msg.datetimetext}</span>
                </div>

                <div className="terminal-response_column status">
                  {formatMapping(msg.displaytext)}
                </div>
              </li>
            ))}
          </ul>
        </Scrollbars>
      }
    </>
  );
};
