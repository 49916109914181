import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import ReactDOM from "react-dom";
import PropTypes, { element } from "prop-types";
import sprite from "../../assets/icons.svg";

/**
 * This is the Modal Component
 *
 * @param {boolean} show - Accepts a boolean value for enabling/disabling the modal (Required)
 * @param {array} children- Accepts array of elements to be shown inside the modal (Required)
 * @param {boolean} animation - Accepts a boolean value for enabling/disabling the animation of the modal
 * @param {boolean} autoFocus - Accepts a boolean value for shifting focus to the modal
 * @param {boolean} backdrop - Accepts a boolean value to include a backdrop component for the modal
 * @param {boolean} centered - Accepts a boolean value for vertically positioning the modal
 * @param {string} size - Accepts strings ('sm', 'lg', 'md', 'xl') for assigning the size of the modal
 * @param {boolean} scrollable - Accepts a boolean value for making the modal scrollable
 * @param {func} onShow - Accepts a function which will get triggered on the onShow event of the modal
 * @param {func} onHide - Accepts a function which will get triggered on the onHide event of the modal
 *
 * @example
 *
 * <QIModal show={show} animation={false} size="lg" centered scrollable onShow={handleShow} onHide={handleClose}>
 */

export const QIModal = ({ children, show, backdrop = true, onHide, className, size = "large" }) => {
  const appRoot = document.getElementById("root");

  return ReactDOM.createPortal(
    <>
      {show ? (
        <>
          <div
            className={`qi-modal ${className}`}
            onClick={backdrop ? onHide : () => {}}
            tabIndex="-1"
          >
            <div className={`qi-modal_dialog ${size}`} onClick={(e) => e.stopPropagation()}>
              <div className="qi-modal_content border border-gray-400 shadow-md shadow-gray-400">
                {children}
              </div>
            </div>
          </div>
          <div className="qi-modal_backdrop"></div>
        </>
      ) : (
        <></>
      )}
    </>,
    appRoot
  );
};

QIModal.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.any,
  animation: PropTypes.bool,
  autoFocus: PropTypes.bool,
  centered: PropTypes.bool,
  scrollable: PropTypes.bool,
  size: PropTypes.oneOf(["full", "xl-large", "large", "medium", "small"]),
  className: PropTypes.string,
  backdrop: PropTypes.oneOf(["static", true, false]),
  onShow: PropTypes.func,
  onHide: PropTypes.func,
};

QIModal.defaultProps = {
  animation: true,
  autoFocus: true,
  backdrop: true,
  centered: true,
  scrollable: false,
  size: "large",
  className: "",
  onShow: () => {},
  onHide: () => {},
};

/**
 * This is the Header of the Modal Component
 *
 * @param {string} children- Accepts string to be shown inside the modal header (Required)
 * @param {func} onHide- Accepts a function which will be triggered on clicking the closeButton on the header of the modal
 * @param {bool} closeButton- Accepts boolean value to show/not show the close button on the header of the modal
 * @param {string} closeLabel- Accepts string to provide a label for the closeButton on the header of the modal
 *
 * @example
 *
 *  <QIModalHeader onHide={handleHide} closeButton closeLabel="Cancel">Title</QIModalHeader>
 */

export const QIModalHeader = ({ children, onHide, className, closeButton = true }) => {
  return (
    <div className={`qi-modal_header ${className}`}>
      {children}
      {closeButton && onHide && (
        <button className="qi-modal_close" onClick={onHide}>
          <span className="icon-wrapper">
            <svg className="icon">
              <use href={`${sprite}#cross`}></use>
            </svg>
          </span>
        </button>
      )}
    </div>
  );
};

QIModalHeader.propTypes = {
  children: PropTypes.any.isRequired,
  closeButton: PropTypes.bool,
  closeLabel: PropTypes.string,
  onHide: PropTypes.func,
};

QIModalHeader.defaultProps = {
  closeButton: true,
  closeLabel: "Close",
};

/**
 * This is the Body of the Modal Component
 *
 * @param {string} children- Accepts string to be shown inside the modal body (Required)
 *
 * @example
 *
 * <QIModalBody>Body</QIModalBody>
 */

export const QIModalBody = ({ children, className }) => {
  return (
    <Scrollbars autoHeight autoHeightMax="calc(100vh - 240px)">
      <div className={`qi-modal_body ${className}`}>{children}</div>
    </Scrollbars>
  );
};

QIModalBody.propTypes = {
  children: PropTypes.any.isRequired,
};

/**
 * This is the Footer of the Modal Component
 *
 * @param {element} children- Accepts elementsto be shown inside the modal footer (Required)
 *
 * @example
 *
 * <QIModalFooter> <QIButton onClick={handleClose}> OK </QIButton> </QIModalFooter>
 */

export const QIModalFooter = ({ children, className, ...props }) => {
  return <div className={`qi-modal_footer ${className}`}>{children}</div>;
};

QIModalFooter.propTypes = {
  children: PropTypes.any.isRequired,
};
