import React, { useState, useEffect } from "react";
import { usePagination, useSearchQuery } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared/";
import { LocalizationForm } from "./Form";
import { ImportLocalizationForm } from "./ImportLocalization";
import { useDelete } from "../../../hooks";
import { useGetLocalizationsQuery, useDeleteLocalizationMutation } from "../services";
import * as Components from "../../../components";
import sprite from "../../../assets/icons.svg";
import { useGetLocalizationDownloadQuery } from "../services";
import { ToastContainer, toast } from "react-toastify";

export const LocalizationContainer = () => {
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [importLocalizationForm, setImportLocalizationForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [downloadLocalizationUrl, setDownloadLocalizationUrl] = useState("");
  const [downloadLocalization, setDownloadLocalization] = useState(false);
  const [importSuccess, setImportSuccess] = useState({});

  const { page, setPage, perPage, pagination } = usePagination();
  const { setSimpleSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "key_or_value_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    // order_by: "localization.last_updated",
    // order_dir: "desc",
  };

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetLocalizationsQuery({
    ...queryParams,
    q,
  });

  const { data: exportLanguage = {} } = useGetLocalizationDownloadQuery(
    { q }
    // { skip: !downloadLocalization }
  );

  useEffect(() => {
    if (exportLanguage?.url) {
      setDownloadLocalizationUrl(exportLanguage?.url);
    }
  }, [exportLanguage]);

  const {
    deleteItem: deleteLocalization,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteLocalizationMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const uploadCsvHandler = () => {
    setImportLocalizationForm(true);
  };

  const handleExport = () => {
    setDownloadLocalization(true);
  };

  const downloadCsvHandler = () => {
    return (
      <Components.QIButton
        className="qi-btn primary md"
        icon={{ src: `${sprite}#download`, className: "download-icon" }}
        onClick={() => handleExport()}
      >
        {(downloadLocalizationUrl && (
          <a href={`${downloadLocalizationUrl}`} className="flex items-center link-export">
            Export
          </a>
        )) ||
          "Export"}
      </Components.QIButton>
    );
  };

  const headers = [
    { label: "Key", key: "key", className: "key" },
    { label: "Value", key: "value", className: "value" },
    { label: "Language", key: "language", className: "language" },
    {
      label: "Profile",
      key: "application_variant",
      className: "application",
    },
    { label: "Added By", key: "added_by", className: "added-by" },

    { label: "Last Updated", key: "updated_at", className: "last-updated" },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }

    return name;
  };

  return (
    <>
      {importSuccess?.fail_count > 0 &&
        toast.error(
          `${importSuccess?.fail_count} key(s) has/have failed. Please check if any cell is empty.`,
          {
            toastId: "",
            onClose: () => setImportSuccess({}),
          }
        )}
      <article className="main-container">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          groupName="other"
          heading="Configurations"
          uploadHandler={uploadCsvHandler}
          customHandler={downloadCsvHandler}
          handleSearchKey={setSimpleSearch}
          simpleSearchPlaceHolder="Search by Key, Value"
        />
        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          headers={headers}
          data={data.data}
          pagination={{ ...pagination, count: data.total_count }}
          onDelete={deleteClickHandler}
          onEdit={editClickHandler}
          className="localization"
          systemDefault={false}
        />
      </article>

      {showForm && <LocalizationForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}

      {importLocalizationForm && (
        <ImportLocalizationForm
          idToEdit={idToEdit}
          closeForm={() => setImportLocalizationForm(false)}
          setImportSuccess={setImportSuccess}
        />
      )}

      <AdminSharedDeleteModal
        show={showDeleteWarning}
        resourceName="Localization"
        error={deleteErrorMsg}
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg();
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteLocalization({ id: idToDelete })}
      />
      <ToastContainer />
    </>
  );
};
