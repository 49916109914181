import React, { useState, useEffect } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { loginFromValidationSchema } from "../validations";
import { QIButton, QIInput } from "../../../components";
import { useCreateAuthMutation } from "../service";
import { FMDPStorage } from "../../../shared/helper";
import { loader } from "../../../assets";
import sprite from "../../../assets/icons.svg";
import { ClientLogin } from "./ClientLogin";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const LoginForm = () => {
  const query = useQuery();
  const username = query.get("username");
  const token = query.get("accesstoken");
  const callbackUrl = query.get("accessdomain");
  const clientName = query.get("clientname");

  const navigate = useNavigate();
  const [login, { isSuccess: loginSuccess, data, error: loginError }] = useCreateAuthMutation({
    fixedCacheKey: "shared-sso-data",
  });

  const accessToken = sessionStorage.getItem("fmdp-access-token");

  // Auto validate the token if exist
  useEffect(() => {
    if (accessToken) {
      login({});
    } else {
      FMDPStorage.clear();
    }
  }, []);

  // Clean the storage in case of loginError
  useEffect(() => {
    if (loginError && accessToken) {
      FMDPStorage.clear();
    }
  }, [loginError]);

  // Navigate if login succeed
  useEffect(() => {
    if (accessToken && loginSuccess && data) {
      FMDPStorage.set("current-user", data);
      navigate("/app-selection");
    }
  }, [loginSuccess]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginFromValidationSchema,
    onSubmit: (values) => {
      login(values);
    },
  });

  return (
    <main className="login-layout">
      {username && callbackUrl && token && clientName ? (
        <ClientLogin
          username={username}
          callbackUrl={callbackUrl}
          token={token}
          clientName={clientName}
        />
      ) : (
        /*Authentication */
        <>
          {!accessToken && (
            <div className="login-form-container">
              <div className="alert alert-danger visually-hidden">
                {loginError && loginError.status === 401 && (
                  <p className="text-center text-red-500 text-sm mb-4">
                    Invalid Email or Password, Try Again.
                  </p>
                )}

                {loginError &&
                  loginError.status === 400 &&
                  loginError.data.base.includes("suspended") && (
                    <p className="text-center text-red-500 text-sm mb-4">
                      Your account has been suspended. Please contact the Administrator for more
                      information.
                    </p>
                  )}

                {!accessToken &&
                  loginError &&
                  (loginError?.status !== 503 || loginError?.status !== 500) &&
                  loginError?.status !== 401 &&
                  loginError?.status !== 400 && (
                    <p className="text-center text-red-500 text-sm mb-4">
                      Something went wrong. Please check back later.
                    </p>
                  )}
              </div>
              <form onSubmit={formik.handleSubmit}>
                <QIInput
                  placeholder="Email"
                  label="Email"
                  name="email"
                  {...formik.getFieldProps("email")}
                  error={formik.touched.email && formik.errors.email}
                />
                <QIInput
                  autoComplete="off"
                  label="Password"
                  name="name"
                  placeholder="Password"
                  type="password"
                  {...formik.getFieldProps("password")}
                  error={formik.touched.password && formik.errors.password}
                />

                <QIButton
                  className="primary w-full mt-4"
                  icon={{ src: `${sprite}#next`, className: "next-icon" }}
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Sign in
                </QIButton>
              </form>

              <div className="text-center mt-10">
                <NavLink to="/password-reset">Forgot Password</NavLink>
              </div>
            </div>
          )}
          {accessToken && !loginError && (
            <section className="text-center happenings">
              <img src={loader} />
            </section>
          )}
          {accessToken && loginError && (
            <section className="text-center happenings">
              <p className="text-red-600">Could not Sign you In, Something went wrong.</p>
            </section>
          )}

          {/* Error Handling for Bad Server 500 and 503*/}
          {(loginError?.status === 503 || loginError?.status === 500) && (
            <section className="text-center happenings">
              <p className="text-red-600">
                Sorry for the inconvenience. Our server is currently unavailable. Please check back
                later. Thank you for your understanding.
              </p>
            </section>
          )}
        </>
      )}
    </main>
  );
};
