import React, { useState, useEffect } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { MPSharedTable, MPSharedDeleteModal, MPSharedHeader } from "../Shared";
import { useGetVModelsQuery, useDeleteVModelMutation } from "../services";
import { VModelForm } from "./form";
import { VehicleModelAdvanceSearch } from "./advanceSearch";
import { useDelete } from "../../../hooks";
import { getLocalizedString } from "../../../shared/translation";

export const VModelsContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, advanceSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    error,
    isFetching,
    isSuccess,
  } = useGetVModelsQuery({
    page,
    per_page: perPage,
    order_by: "vehicle_models.created_at",
    order_dir: "desc",
    q,
  });

  const {
    deleteItem: deleteVModel,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteVModelMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);

  const onEdit = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const onDelete = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const onCreate = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const headers = [
    { label: getLocalizedString("name", "Name"), key: "name" },
    {
      label: getLocalizedString("manufacturer", "Manufacturer"),
      key: "vehicle_manufacturer_name",
      className: "manufacturer",
    },
    {
      label: getLocalizedString("model", "Model"),
      key: "vehicle_manufacturer_model_name",
      className: "vehicle-manufacturer-model",
    },
    {
      label: getLocalizedString("variant", "Variant"),
      key: "vehicle_manufacturer_model_variant_name",
      className: "model-variant",
    },
    {
      label: getLocalizedString("capacity", "Capacity"),
      key: "capacity",
      className: "capacity text-center",
    },
    {
      label: getLocalizedString("engine_capacity", "Engine Capacity"),
      key: "engine_capacity",
      className: "engine-capacity",
    },
    { label: getLocalizedString("year", "Year"), key: "year", className: "year" },
    {
      label: getLocalizedString("types", "Types"),
      key: "vehicle_type_name",
      className: "types",
    },
    {
      label: getLocalizedString("classes", "Classes"),
      key: "vehicle_class_name",
      className: "clasess",
    },
    {
      label: getLocalizedString("added_on", "Added On"),
      key: "created_at",
      className: "date",
    },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((item) => item.id === id);
    if (index >= 0) {
      name = data?.data[index]?.name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container specifications">
        <MPSharedHeader
          groupName="vehicle"
          createNewHandler={onCreate}
          resourceName="Vehicle Models"
          heading={getLocalizedString("manage_vehicles", "Manage Vehicles")}
          handleSearchKey={(value) => setSimpleSearch(value)}
          handleAdvanceSearchKeys={(value) => setAdvanceSearch(null)}
          simpleSearchPlaceholder={getLocalizedString("search_by_name", "Search by Name")}
        >
          <VehicleModelAdvanceSearch
            onSearch={(value) => setAdvanceSearch(value)}
            activeSearch={advanceSearch}
          />
        </MPSharedHeader>

        <MPSharedTable
          resourceName="Vehicle Models"
          data={data?.data || []}
          error={error}
          isLoading={isFetching}
          isSuccess={isSuccess}
          headers={headers}
          onEdit={onEdit}
          onDelete={onDelete}
          pagination={{ ...pagination, count: data.total_count }}
          className="vehicle-specifications"
          auditResource="VehicleModel"
          auditKey="name"
        />
      </article>

      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={getLocalizedString("vehicle_model", "Vehicle Model")}
        resourceName="driver"
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        error={deleteErrorMsg}
        itemName={getDeleteItemName(idToDelete)}
        onDelete={() => deleteVModel({ id: idToDelete })}
      />
      {showForm && <VModelForm idToEdit={idToEdit} onClose={setShowForm} />}
    </>
  );
};
