import { useState, useMemo, useEffect } from "react";
import sprite from "../../assets/icons.svg";
import { Scrollbars } from "react-custom-scrollbars";
import { QIDropdown } from "../QIDropdown";
import { QIInput } from "../QIInput";
import { displayTableConfigName } from "../../containers/Tracking/utils";

/**
 * This is the Chip Component
 *
 * @param {array} data- Accepts a data array of objects filtered by query params for searching
 * @param {array} allData- Accepts a data array of objects
 * @param {func/string} labelKey- Accepts a string/function for label
 * @param {integer/string} labelKey- Accepts an key for the chip to be deleted
 * @param {func} onDelete- Accepts a function for deleting a chip when confirmed
 * @param {func} onDeleteRequest- Accepts a function for wanting to delete a chip
 * @param {func} onDeleteRequestCancel- Accepts a function for cancelling the request to delete a chip
 * @param {boolean} readOnly- Accepts a boolean value for the dropdown to be disabled or not
 * @param {func} actionError- Accepts a callback function for handling titles
 * @param {func} getRowClass- Accepts a callback function for handling classnames for each dropdown
 * @param {func} isSelectable- Accepts a callback function for handling whether a particular chip is selectable
 */

const QIChip = ({
  data,
  allData,
  labelKey,
  deleteKey,
  onDelete,
  onDeleteRequest,
  onDeleteRequestCancel,
  readOnly,
  actionError = () => "",
  getRowClass = () => "",
  isSelectable = () => true,
  isDeletable = () => true,
  variableData,
}) => {
  return (
    <>
      {(allData || data) && (
        <span
          className={`qi-chip ${deleteKey === (allData || data).id ? "alert" : ""} ${
            getRowClass(allData || data) || ""
          }`}
          title={actionError(allData || data)}
        >
          <div className="qi-chip_data">
            {typeof labelKey === "function"
              ? labelKey(allData || data)
              : displayTableConfigName(variableData, (allData || data)[labelKey]) ||
                (allData || data)[labelKey]}
          </div>
          {deleteKey !== (allData || data).id && !readOnly && isDeletable(allData || data) && (
            <span className="qi-chip_cross" onClick={() => onDeleteRequest(allData || data)}>
              <svg className={`icon`}>
                <use href={`${sprite}#cross`}></use>
              </svg>
            </span>
          )}
          {deleteKey === (allData || data).id && (
            <span className="rounded">
              <span
                onClick={() => onDelete(allData || data)}
                className="mx-1 text-red-400 bg-white px-2 rounded-xl clickable"
              >
                Yes
              </span>
              <span
                onClick={onDeleteRequestCancel}
                className="mx-1 text-green-400 bg-white px-2 rounded-xl clickable"
              >
                No
              </span>
            </span>
          )}
        </span>
      )}
    </>
  );
};

// When a new item is searched
const AddNewChip = ({ setSearchKey, onSearch, addNewTitle }) => {
  return (
    <span className="icon-wrapper add-new" title={addNewTitle}>
      <svg
        className="icon icon-add"
        onClick={() => {
          try {
            setSearchKey("");
            onSearch("");
          } catch (e) {
            console.log(e);
          }
        }}
      >
        <use href={`${sprite}#add`}></use>
      </svg>
    </span>
  );
};

function debounce(func, delay = 500) {
  let tempVar;

  return function () {
    clearTimeout(tempVar);
    tempVar = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}

/**
 * This is the Multi select dropdown Component
 *
 * @param {string} label- Accepts a string for label
 * @param {func} onChange- Accepts a callback function to handle onChange event
 * @param {string} className- Accepts custom classNames to be added
 * @param {integer/string} labelKey- Accepts an key to handle the label key
 * @param {array} data- Accepts a data array of objects filtered by query params for searching
 * @param {array} allData- Accepts entire data array of objects to generate the chips selected
 * @param {array} selected- Accepts the selected list of dropdowns
 * @param {func} onSearch- A function to update the simpleSearchKey value
 * @param {string} onSearchPlaceHolder- A string which holds the placeholder for the simple search
 * @param {boolean} readOnly- Accepts a boolean value for the dropdown to be disabled or not
 * @param {func} actionError- Accepts a callback function for handling titles
 * @param {func} getRowClass- Accepts a callback function for handling classnames for each dropdown
 * @param {func} isSelectable- Accepts a callback function for handling whether a particular chip is selectable
 * @param {string} error- Accepts a string to handle error mesasge
 * @param {boolean} multiSelect- Accepts a boolean value to check whether it works as a multi-select or single-select dropdown
 */

export const QIMultiSelectDropDown = ({
  label,
  onChange,
  className = "",
  labelKey = "name",
  data = [],
  allData = [],
  selected = [],
  onSearch,
  onSearchPlaceHolder = "",
  readOnly,
  actionError = () => "",
  getRowClass = () => "",
  isSelectable = () => true,
  isDeletable = () => true,
  error,
  multiSelect = true,
  multiSelectWithoutChip = false,
  addNewTitle = "Add New",
  variableData,
  ...props
}) => {
  //Maintains the key for delete operation
  const [deleteKey, setDeleteKey] = useState(null);

  //Maintains the key for search operation
  const [searchKey, setSearchKey] = useState("");

  //Maintains the list of multiselect chips selected
  const [chipDataSelected, setChipDataSelected] = useState([]);

  //Clear the chip selected list on unmount
  useEffect(() => {
    return () => {
      setChipDataSelected(selected);
    };
  }, []);

  //When deleting a chip if confirmed
  const onClose = (chipData) => {
    let tempSelected = selected.filter((item) => item !== chipData.id);
    setDeleteKey("");
    onChange(tempSelected);
  };

  //When adding a new data from the list
  const addNew = (chipData) => {
    setSearchKey("");
    if (!selected.find((datum) => datum === chipData.id)) {
      const tempSelected = [...selected, chipData.id];
      onChange(tempSelected);
    }
    setChipDataSelected((prevData) => [...prevData, chipData]);
  };

  //When wanting to delete a chip
  const onDeleteRequest = (data) => {
    setDeleteKey(data.id);
  };

  //When cancelling the request to delete a chip
  const onDeleteRequestCancel = () => {
    setDeleteKey(null);
  };

  const callApi = useMemo(() => {
    return onSearch
      ? debounce((value) => {
          onSearch(value);
        })
      : () => {};
  }, []);

  const handleSearch = (value) => {
    setSearchKey(value);
    callApi(value);
  };

  return (
    <div
      className={`qi-multiselect-dropdown qi-form-element ${
        multiSelectWithoutChip && "no-outline"
      } ${error ? "error" : ""} ${readOnly ? "disabled" : ""} ${className || ""}`}
    >
      <label className="qi-multiselect-dropdown_label" data-testid="input-label">
        {label}
      </label>

      <div className="qi-multiselect-dropdown_wrapper">
        <div className="flex">
          {selected?.length > 0 && !multiSelectWithoutChip && (
            <div className="qi-multiselect-dropdown_chip-container device-chip">
              {(allData || data) &&
                (allData.length > 0 || data.length > 0) &&
                selected.map((selectedItem, index) => (
                  <QIChip
                    allData={chipDataSelected?.find((item) => selectedItem === item.id)}
                    data={data?.find((item) => selectedItem === item.id)}
                    labelKey={labelKey}
                    key={index}
                    deleteKey={deleteKey}
                    onDelete={onClose}
                    onDeleteRequest={onDeleteRequest}
                    onDeleteRequestCancel={onDeleteRequestCancel}
                    readOnly={readOnly}
                    isSelectable={isSelectable}
                    isDeletable={isDeletable}
                    actionError={actionError}
                    getRowClass={getRowClass}
                    variableData={variableData}
                  />
                ))}
            </div>
          )}
          <div className="qi-multiselect-dropdown_wrapper new-device-chip">
            {!readOnly && multiSelect && (
              <QIDropdown
                toggleComponent={
                  <AddNewChip
                    setSearchKey={setSearchKey}
                    onSearch={onSearch}
                    addNewTitle={addNewTitle}
                  />
                }
              >
                {onSearch && (
                  <form
                    className="chip-search"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <QIInput
                      value={searchKey}
                      placeholder={onSearchPlaceHolder}
                      className="qi-input_text chip-search_text"
                      onChange={(e) => handleSearch(e.target.value)}
                      icon={{ src: `${sprite}#search`, className: "search-icon" }}
                      focus={true}
                    />
                  </form>
                )}

                <Scrollbars autoHeight autoHeightMax="150px">
                  <ul className="available-in-list backends">
                    {(!data || data.length == 0) && (
                      <li className="no-item-availlable">No Item Available.</li>
                    )}
                    {data?.map((datum, index) => (
                      <li
                        className={`available-in-list_item ${
                          selected?.find((record) => datum.id === record) ? "active" : ""
                        } ${getRowClass({ forSelect: true, ...datum }) || ""}`}
                        onClick={!isSelectable(datum) ? () => {} : () => addNew(datum)}
                        title={`${actionError(datum)}`}
                        key={index}
                      >
                        {typeof labelKey === "function"
                          ? labelKey(datum)
                          : displayTableConfigName(variableData, datum[labelKey]) ||
                            datum[labelKey]}
                      </li>
                    ))}
                  </ul>
                </Scrollbars>
              </QIDropdown>
            )}
          </div>
        </div>

        {!readOnly && error && (
          <div className="qi-multiselect-dropdown_error">
            <span className="icon-wrapper">
              <svg className="icon">
                <use href={`${sprite}#info`}></use>
              </svg>
            </span>
            <p className="qi-multiselect-dropdown_error_message">{error}</p>
          </div>
        )}
      </div>
    </div>
  );
};
