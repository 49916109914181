import React, { useState, useEffect } from "react";
import { Icon, Style, Stroke } from "ol/style.js";
import { QIMapLoader } from "../../../components";
import { useGetVehicleRoutingQuery } from "../services";
import { locationImg } from "../assets";
import moment from "moment";
import { removeDuplicates } from "../utils";
import { toast } from "react-toastify";

/**
 * Component for displaying and tracking vehicle routes on a map.
 * @param {Object} props - Component props.
 * @param {string} props.dockAlign - Alignment of the map container.
 * @param {string} props.deviceId - ID of the device/vehicle.
 * @param {Object} props.realTimeData - Real-time data of the vehicle.
 * @param {function} props.setTrackMapRef - Function to set the map reference.
 * @param {Object} props.trackMapRef - Reference to the map.
 * @param {boolean} props.mapExpand - Whether the map is expanded.
 * @param {function} props.setResetCount - Function to reset a count.
 * @param {Object} props.selectedRange - Selected time range for data retrieval.
 */
export const VehicleTrack = ({
  dockAlign,
  deviceId,
  realTimeData,
  setTrackMapRef,
  trackMapRef,
  mapExpand,
  setResetCount,
  selectedRange,
  maximizeMinimize,
}) => {
  const [trackData, setTrackData] = useState([]); // State to hold tracked vehicle data
  const [page, setPage] = useState(0); // Pagination page for fetched data
  const [totalCount, setTotalCount] = useState(200); // Total count of data to fetch
  const [count, setCount] = useState(0); // Count for layer management

  // This effect handles resetting the state and map layers when selected time range or device ID changes
  useEffect(() => {
    setPage(0);
    setTrackData([]);
  }, [selectedRange, deviceId]);

  // Fetch vehicle routing data using a custom hook
  const { data: resData, error } = useGetVehicleRoutingQuery(
    {
      deviceId,
      per_page: totalCount,
      page,
      start_time: moment(selectedRange.startDate).valueOf(),
      end_time: moment(selectedRange.endDate).valueOf(),
      isTime: true,
    },
    { refetchOnMountOrArgChange: true }
  );

  //Error Message Display
  useEffect(() => {
    if (error?.data?.message) {
      toast.error(error?.data?.message);
    }
  }, [error]);

  // This effect processes the fetched data and real-time data to update the tracked vehicle data
  useEffect(() => {
    try {
      if (deviceId) {
        if (resData?.total_count && page <= Math.round(resData?.total_count / totalCount)) {
          resData?.data.forEach((device_data) => {
            if (device_data?.gps && device_data?.gps?.position?.is_valid) {
              let h = {
                source_id: device_data?.source_id,
                lat: device_data?.gps?.position?.lat,
                lng: device_data?.gps?.position?.lng,
                direction: device_data?.velocity?.direction,
                speed: device_data?.velocity?.speed,
                "010d_vehicle_speed": device_data?.obd?.["010d_vehicle_speed"],
              };
              setTrackData((prev) => [...prev, h]);
            }
          });
          setPage((c) => c + 1);
        }
      }

      // Update tracked data with real-time data if within the last 25 hours
      const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
      const selectedTime = moment(selectedRange.startDate).format("YYYY-MM-DD HH:mm:ss");
      const duration = moment.duration(moment(currentTime).diff(moment(selectedTime)));
      const hours = duration.asHours();

      if (hours <= 25 && realTimeData?.gps && realTimeData?.gps?.position?.is_valid) {
        let h = {
          source_id: realTimeData?.source_id,
          lat: realTimeData?.gps?.position?.lat,
          lng: realTimeData?.gps?.position?.lng,
          direction: realTimeData?.velocity?.direction,
          speed: realTimeData?.velocity?.speed,
          "010d_vehicle_speed": realTimeData?.obd?.["010d_vehicle_speed"],
        };
        setTrackData((prev) => [h, ...prev]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [resData, realTimeData, selectedRange]);

  // This effect updates the map with the tracked vehicle data
  useEffect(() => {
    try {
      if (trackMapRef && trackData.length && deviceId) {
        deviceId && trackMapRef.removeLayerSource(`track_${count - 1}`);
        let layerName = `track_${count}`;
        trackMapRef.addLayer({ name: layerName });

        // Draw the first and last points of the track with specific styles
        trackMapRef.drawTrack({
          layerName: layerName,
          fitWithinView: true,
          trackType: "point",
          data: [{ coordinates: [trackData[0]] }],
          style: {
            point: {
              style: (feature) => {
                return new Style({
                  image: new Icon({
                    color: "#eaff00",
                    crossOrigin: "anonymous",
                    src: locationImg,
                    imgSize: [38, 38],
                    rotation: feature.direction,
                  }),
                });
              },
            },
          },
        });

        trackMapRef.drawTrack({
          layerName: layerName,
          fitWithinView: true,
          trackType: "point",
          data: [{ coordinates: [trackData[trackData.length - 1]] }],
          style: {
            point: {
              style: (feature) => {
                return new Style({
                  image: new Icon({
                    color: "#0000ff",
                    crossOrigin: "anonymous",
                    src: locationImg,
                    imgSize: [16, 16],
                    rotation: feature.direction,
                  }),
                });
              },
            },
          },
        });

        // Draw the track line connecting all points
        trackMapRef.drawTrack({
          layerName: layerName,
          fitWithinView: true,
          trackType: "line",
          data: [{ coordinates: removeDuplicates(trackData) }],
          style: {
            line: {
              style: (feature) => {
                return new Style({
                  stroke: new Stroke({
                    color: "#000",
                    width: 3,
                  }),
                });
              },
            },
          },
        });

        setCount((c) => c + 1);
        trackMapRef.performFit(layerName);
      } else {
        trackMapRef?.removeLayer(`track_${count - 1}`);
      }
    } catch (e) {
      console.log(e);
    }
  }, [trackMapRef, trackData, deviceId]);

  return (
    <div className={`map-container ${dockAlign && "qiMap-split"}`}>
      <QIMapLoader
        containerId="device-map-container"
        mapExpand={mapExpand}
        setMapRef={setTrackMapRef}
        dockAlign={dockAlign}
        maximizeMinimize={maximizeMinimize}
      ></QIMapLoader>
    </div>
  );
};
