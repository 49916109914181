import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import {
  ClientSection,
  UsersContainer,
  DMansContainer,
  DModelsContainer,
  DeviceModel,
  VTypesContainer,
  VClassesContainer,
  VMansContainer,
  VManufacturer,
  VManModelsContainer,
  VManModelVariantsContainer,
  VModelsContainer,
  VariablesContainer,
  VariableMappingsContainer,
  BackendsContainer,
  GeocodersContainer,
  ObdCodesContainer,
  BatchCommandContainer,
  DevicesContainer,
  VehiclesContainer,
  AdminDashboard,
  UserProfile as AdminUserProfile,
  AuditTrail as AdminAuditTrail,
  GeolocationContainer as AdminGeolocationContainer,
  MSPContainer as AdminMSPContainer,
  LocalizationContainer as AdminLocalizationContainer,
} from "./Admin";

import { LoginForm, RoleSelection, ResetPassword, ResetPasswordOtp } from "./Authentication";

import {
  DeviceContainer as MPDeviceContainer,
  RolesContainer,
  UsersContainer as MPUsersContainer,
  VariablesTypesContainers as MPVariablesTypesContainer,
  VClassesContainer as MPVClassesContainer,
  VTypesContainer as MPVTypesContainer,
  VMansContainer as MpVManContainer,
  VManModelsContainer as MPVManModelsContainer,
  VModelsContainer as MPVModelsContainer,
  VManModelVariantContainer as MPVManModelVariantContainer,
  VehiclesContainer as MPVehiclesContainer,
  VehicleSection as MPVehicleSection,
  MPDashboard,
  UserProfile as MPUserProfile,
  AuditTrail as MPAuditTrail,
  BackendsContainer as MPBackendsContainer,
  DModelsContainer as MPDModelsContainer,
  DeviceGroupsContainer as MPDeviceGroupsContainer,
  VehicleGroupsContainer as MPVehicleGroupsContainer,
  UserGroupsContainer as MPUserGroupsContainer,
  CustomizationContainer,
  ApiListContainer,
  GeofenceContainer as MPGeofenceContainer,
  ReportConfigurationsContainer as MPReportConfigurationsContainer,
  EventConfigurationsContainer as MPEventConfigurationsContainer,
  EventConfigurationsPlanContainer as MPEventConfigurationsPlanContainer,
  TripsContainer as MPTripsContainer,
  SimCardsContainer as MPSimCardsContainer,
} from "./ManagementPortal";

import {
  VehicleContainer as TVehicleContainer,
  DeviceContainer as TDeviceContainer,
  VehicleDetails as TVehicleDetails,
  RouteDetails as TRouteDetails,
  TripsContainer as TTripsContainer,
  ReportsContainer as TReportsContainer,
  DashBoardContainer as TDashBoardContainer,
  EventsContainer as TEventContainer,
  ActivitiesContainer as TActivitiesContainer,
} from "./Tracking";

import { JsonRenderer } from "../assets/clients/JsonRenderer";

import { Documentation } from "./Documentation";

import { AdminLayout } from "./Admin/layout";
import { MPLayout } from "./ManagementPortal/layout";
import { TrackingLayout } from "./Tracking/layout";

export const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/superadmin/" element={<AdminLayout />}>
        <Route path="devices">
          <Route path="manufacturers" element={<DMansContainer />} />
          <Route path="models" element={<DModelsContainer />} />
          <Route path="models/:deviceModelId" element={<DeviceModel />} />
          <Route path="list" element={<DevicesContainer />} />
          <Route index element={<Navigate to="list" />} />
        </Route>

        <Route path="vehicles">
          <Route path="types" element={<VTypesContainer />} />
          <Route path="classes" element={<VClassesContainer />} />
          <Route path="manufacturers" element={<VMansContainer />} />
          <Route path="manufacturers/:vManId" element={<VManufacturer />} />
          <Route path="manufacturer_models" element={<VManModelsContainer />} />
          <Route path="variants" element={<VManModelVariantsContainer />} />
          <Route path="specifications" element={<VModelsContainer />} />
          <Route path="list" element={<VehiclesContainer />} />
          <Route index element={<Navigate to="list" />} />
        </Route>
        <Route path="others">
          <Route path="variables" element={<VariablesContainer />} />
          <Route path="variable_mappings" element={<VariableMappingsContainer />} />
          <Route path="backends" element={<BackendsContainer />} />
          <Route path="geocoders" element={<GeocodersContainer />} />
          <Route path="geolocation_plan" element={<AdminGeolocationContainer />} />
          <Route path="obd_codes" element={<ObdCodesContainer />} />
          <Route path="batch_command" element={<BatchCommandContainer />} />
          <Route index element={<Navigate to="variables" />} />
          <Route path="msp" element={<AdminMSPContainer />} />
          <Route path="localization" element={<AdminLocalizationContainer />} />
        </Route>
        <Route path="clients" element={<ClientSection />} />
        <Route path="users" element={<UsersContainer />} />
        <Route path="user-profile" element={<AdminUserProfile />} />
        <Route path="audit-trail" element={<AdminAuditTrail />} />
        <Route index element={<AdminDashboard />} />
      </Route>

      <Route path="*" element={<Navigate to="/superadmin/" />} />
    </Routes>
  );
};

//Routes for management portal
export const MPRoutes = () => {
  return (
    <Routes>
      <Route path="/management_portal" element={<MPLayout />}>
        <Route path="" element={<Navigate to="dashboard" />} />
        <Route path="dashboard" element={<MPDashboard />} />
        <Route path="devices">
          <Route path="list" element={<MPDeviceContainer />} />
          <Route path="models" element={<MPDModelsContainer />} />
          <Route index element={<Navigate to="list" />} />
        </Route>
        <Route path="others">
          <Route path="backends" element={<BackendsContainer />} />
          <Route path="geofence" element={<MPGeofenceContainer />} />
          <Route index element={<Navigate to="backends" />} />
        </Route>

        <Route path="configurations">
          <Route path="backends" element={<MPBackendsContainer />} />
          <Route path="geofence" element={<MPGeofenceContainer />} />
          <Route path="report" element={<MPReportConfigurationsContainer />} />
          <Route path="events" element={<MPEventConfigurationsContainer />} />
          <Route path="event_plans" element={<MPEventConfigurationsPlanContainer />} />
          <Route path="trips" element={<MPTripsContainer />} />
          <Route path="sim_cards" element={<MPSimCardsContainer />} />
          <Route index element={<Navigate to="backends" />} />
        </Route>

        <Route path="vehicles">
          <Route path="classes" element={<MPVClassesContainer />} />
          <Route path="types" element={<MPVTypesContainer />} />
          <Route path="manufacturers" element={<MpVManContainer />} />
          <Route path="manufacturers_models" element={<MPVManModelsContainer />} />
          <Route path="specifications" element={<MPVModelsContainer />} />
          <Route path="variants" element={<MPVManModelVariantContainer />} />
          <Route path="list" element={<MPVehiclesContainer />} />
          <Route index element={<Navigate to="list" />} />
        </Route>

        <Route path="groups">
          <Route path="device" element={<MPDeviceGroupsContainer />} />
          <Route path="vehicle" element={<MPVehicleGroupsContainer />} />
          <Route path="user" element={<MPUserGroupsContainer />} />
          <Route index element={<Navigate to="vehicle" />} />
        </Route>

        <Route path="users_roles">
          <Route path="users" element={<MPUsersContainer />} />
          <Route path="roles" element={<RolesContainer />} />
          <Route index element={<Navigate to="users" />} />
        </Route>

        <Route path="audit-trail" element={<MPAuditTrail />} />
        <Route path="user-profile" element={<MPUserProfile />} />

        <Route path="client_config">
          <Route path="portal_customization" element={<CustomizationContainer />} />
          <Route path="api_keys" element={<ApiListContainer />} />
          <Route index element={<Navigate to="portal_customization" />} />
        </Route>
        <Route path="*" element={<Navigate to="/management_portal/dashboard" />} />
      </Route>

      <Route path="*" element={<Navigate to="/management_portal/dashboard" />} />
    </Routes>
  );
};

export const TrackingRoutes = () => {
  return (
    <TrackingLayout>
      <Routes>
        <Route path="/tracking">
          <Route path="" element={<Navigate to="vehicles" />} />
          <Route path="dashboard" element={<TDashBoardContainer />}></Route>
          <Route path="vehicles" element={<TVehicleContainer />} />
          <Route path="devices" element={<TDeviceContainer />} />
          <Route path="device-details/:imei" element={<TVehicleDetails />} />
          <Route path="track/:imei" element={<TRouteDetails />}></Route>
          <Route path="trips" element={<TTripsContainer />}></Route>
          <Route path="reports" element={<TReportsContainer />}></Route>
          <Route path="events" element={<TEventContainer />}></Route>
          <Route path="activities" element={<TActivitiesContainer />}></Route>
          <Route path="*" element={<Navigate to="vehicles" />} />
        </Route>
        <Route path="*" element={<Navigate to="/tracking/vehicles" />} />
      </Routes>
    </TrackingLayout>
  );
};

export const AuthenticationRoutes = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<LoginForm />} />
      <Route exact path="/app-selection" element={<RoleSelection />} />
      <Route exact path="/password-reset" element={<ResetPassword />} />
      <Route exact path="/users/reset_password/:id" element={<ResetPasswordOtp />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

// Add the routes which doesn't needs the authentication like docs, product show page.
// Also the all of these paths must need to be added inside App.js in  UN_AUTHETICATED_ROUTES list
export const UnAuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route exact path="/documentation" element={<Documentation />} />
      <Route path="/clients/:client_id/config.json" element={<JsonRenderer />} />
    </Routes>
  );
};
