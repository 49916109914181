import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchQuery, usePagination } from "../../../hooks";
import { MPSharedHeader, MPSharedTable } from "../Shared/";
import { useGetDModelsQuery } from "../services";
import { Link } from "react-router-dom";
import { useQuery } from "../../../hooks";
import { TickOrCross, SvgIcon } from "../../Shared";
import { DModelForm } from "./form";
import {getLocalizedString} from "../../../shared/translation";

export const DModelsContainer = () => {
  const dispatch = useDispatch();

  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_device_manufacturer_name_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    order_dir: "desc",
  };

  const query = useQuery();
  let filterHeader = "";
  const filters = [
    {
      label: getLocalizedString("manufacturer", " Manufacturer") + ":" ,
      key: "device_manufacturer_id",
    },
    {
      label:
        getLocalizedString("trip_event_configuration", " Trip Event Configuration") + ":" ,
      key: "trip_event_configuration_id",
    },
  ];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterHeader = `${filter.label} ${query.get("name")}`;
    }
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetDModelsQuery({
    page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
    ...queryParams,
    q,
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [selectedDeviceManufacturer, setSelectedDeviceManufacturer] = useState("");
  const [showForm, setShowForm] = useState(false);

  const headers = [
    {
      label: getLocalizedString("name", "Name"),
      key: "name",
      className: "name",
    },
    {
      label: getLocalizedString("device_manufacturer", "Device manufacturer"),
      key: "device_manufacturer_name",
      className: "device-manufacturer",
    },
    {
      label: getLocalizedString("version", "Version"),
      key: "version",
      className: "version",
    },
    { label: getLocalizedString("port", "Port"), key: "port", className: "version" },
    { label: getLocalizedString("year", "Year"), key: "year", className: "year" },
    {
      label: getLocalizedString("remote_lock", "Remote Lock"),
      nestedValue: true,
      getNestedValue: (value) => <TickOrCross flag={value?.is_lockable} />,
      className: "status",
    },
    {
      label: getLocalizedString("devices", "Devices"),
      nestedValue: true,
      className: "devices text-center",
      getNestedValue: ({ id, devices_count, name }) => {
        return (
          <>
            {devices_count > 0 ? (
              <Link
                to={`/management_portal/devices/list?device_model_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {devices_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    {
      label: getLocalizedString("added_on", "Added on"),
      key: "created_at",
      className: "date",
    },
  ];

  const additionalActions = [
    {
      component: (data) => (
        <SvgIcon
          wrapperClass="clickable"
          name="settings"
          onClick={() => {
            setShowForm(true);
            setIdToEdit(data?.id);
            setSelectedDeviceManufacturer(data?.device_manufacturer_name);
          }}
          title="Configurations"
        />
      ),
    },
  ];

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName="device"
          heading={getLocalizedString("manage_devices", "Manage Devices")}
          handleSearchKey={(value) => setSimpleSearch(value)}
          filterText={
            filterHeader &&
            `${getLocalizedString("filtered_by", "Filtered by")} ${filterHeader}`
          }
          simpleSearchPlaceholder={
            getLocalizedString("search_by_name_manufacturer", "Search by Name, Manufacturer")
          }
        />
        <MPSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          className="device-models"
          additionalActions={additionalActions}
          skipClientId={true}
          skipCanEdit={true}
          auditKey=""
        />

        {showForm && (
          <DModelForm
            idToEdit={idToEdit}
            deviceManufacturer={selectedDeviceManufacturer}
            onClose={() => setShowForm(false)}
          />
        )}
      </article>
    </>
  );
};
