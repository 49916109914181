import { useEffect, useState } from "react";
import { loader } from "../../../assets";
import { useCreateAuthMutation } from "../service";
import { FMDPStorage } from "../../../shared/helper";

export const ClientLogin = ({ username, callbackUrl, token, clientName }) => {
  const [tryAgain, setTryAgain] = useState(false);
  const [login, { isSuccess: loginSuccess, data, error: loginError }] = useCreateAuthMutation({
    fixedCacheKey: "shared-sso-data",
  });

  const queryParams = {
    username: username,
    callback_url: callbackUrl,
    token: token,
    client_name: clientName,
  };

  //Call login Api when received the queryparams
  useEffect(() => {
    login(queryParams);
  }, []);

  //Call login Api when clicked on Try Again
  useEffect(() => {
    login(queryParams);
  }, [tryAgain]);

  // For successful login, set the selected app id
  useEffect(() => {
    if (loginSuccess) {
      FMDPStorage.set("selected-app-id", data?.selected_app_id);
      FMDPStorage.set("live-view", true);
    }
  }, [loginSuccess]);

  return (
    <main className="login-layout authentication">
      <div className="login-form-container">
        {!loginSuccess && !loginError && (
          <div>
            <h1 className="page-title">Authenticating...</h1>

            <img className="happenings" src={loader} />
          </div>
        )}

        {loginError && (
          <p className="text-center text-red-500 text-sm mb-4">
            Authentication failed. Please{" "}
            <a href="#" onClick={() => setTryAgain((prevState) => !prevState)}>
              try again
            </a>
            .
          </p>
        )}
      </div>
    </main>
  );
};
