import React, { useState, useEffect, useRef } from "react";
import { QIDropdown, QIModal, QIModalBody, QIModalFooter, QIButton } from "../../../components";
import { useNavigate, Link } from "react-router-dom";
import { SvgIcon } from "../../Shared";
import { FMDPStorage } from "../../../shared/helper";
import { toggleAppInfo } from "../../Authentication/helper";
import { tHelper } from "../../../shared/translation";
import { smallLogo, userImg } from "../../../assets/";
import { Scrollbars } from "react-custom-scrollbars";
import { getLocalizedString } from "../../../shared/translation";

import {
  useCreateAuthMutation,
  useSelectClientAppMutation,
  useSelectSuperAdminMutation,
  useLogoutAppMutation,
} from "../../Authentication/service";
import { useDispatch } from "react-redux";
import { setFlashScreen } from "../../../reduxStore/features";

export const CommonHeader = ({ appName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentLanguage, setCurrentLanguage] = useState(tHelper.currentLanguage);
  const [selectedAppId, setSelectedAppId] = useState(FMDPStorage.get("selected-app-id"));
  const [appToBeSelected, setAppToBeSelected] = useState("");
  const [dropDownSection, setDropDownSection] = useState("");
  const [quickDashboard, setQuickDashBoard] = useState(false);

  const dashboardRef = useRef(null);

  const [
    selectClientApp,
    {
      isSuccess: selectClientAppSuccess,
      data: clientAppData,
      reset: resetSelectClientApp,
      error: selectClientAppError,
    },
  ] = useSelectClientAppMutation({
    fixedCacheKey: "shared-client-app-data",
  });

  const [
    selectSuperAdmin,
    {
      isSuccess: selectSuperAdminSuccess,
      reset: resetSelectSuperAdmin,
      error: selectSuperAdminError,
    },
  ] = useSelectSuperAdminMutation({
    fixedCacheKey: "shared-superadmin-app-data",
  });

  const [logoutApp, { isSuccess: logoutAppSuccess, reset: resetlogoutApp, error: logoutAppError }] =
    useLogoutAppMutation();

  const currentUser = FMDPStorage.get("current-user");

  const clientName = localStorage.getItem("selected-client") || "";

  const logOut = () => {
    logoutApp();
  };

  useEffect(() => {
    if (logoutAppSuccess) {
      FMDPStorage.clear();
      window.location.reload();
    }
  }, [logoutAppSuccess]);

  const [, { data: user }] = useCreateAuthMutation({
    fixedCacheKey: "shared-sso-data",
  });

  const switchApplication = () => {
    if (user.superadmin && selectedAppId !== appToBeSelected && appToBeSelected == "superadmin") {
      selectSuperAdmin();
    } else if (appToBeSelected !== selectedAppId) {
      selectClientApp({ client_app_id: appToBeSelected });
    }
  };

  // Navigate to app and toggle app info once app is changed
  useEffect(() => {
    if (selectClientAppSuccess) {
      if (toggleAppInfo(user, appToBeSelected, clientAppData)) {
        setSelectedAppId(appToBeSelected);
        navigate("/");
      }
      resetSelectClientApp();
    }
  }, [selectClientAppSuccess]);

  // Navigate to admin dashboard if selected as super admin
  useEffect(() => {
    if (selectSuperAdminSuccess) {
      FMDPStorage.set("selected-app", "SuperAdmin");
      FMDPStorage.set("selected-app-id", "superadmin");
      FMDPStorage.set("selected-client", "");
      navigate("/");
      resetSelectSuperAdmin();
    }
  }, [selectSuperAdminSuccess, selectSuperAdminError]);

  const initiateAppChange = (appId) => {
    if (appId !== selectedAppId) {
      setAppToBeSelected(appId);
    }
  };

  const getAppName = (app) => {
    let clientName = "";
    let names = app?.name?.split("-") || [];

    if (names.length > 0) {
      clientName = names[0];
    }

    if (app?.custom_name) {
      return `${clientName} - ${app?.custom_name}`;
    }
    return app?.name;
  };

  //set co_branding status
  useEffect(() => {
    dispatch(setFlashScreen(clientAppData?.user_sessions?.client_app?.client?.co_branding_screen));
  }, [dispatch, clientAppData]);

  const goToDashboard = () => {
    FMDPStorage.set("app_features", null);
    FMDPStorage.set("selected-client", null);
    FMDPStorage.set("selected-app", null);
    FMDPStorage.set("selected-app-id", null);
    navigate("/app-selection");
  };

  const handleClickOutside = (event) => {
    if (dashboardRef.current && !dashboardRef.current.contains(event.target)) {
      // Clicked outside the config div, set showConfig to false
      setQuickDashBoard(false);
    }
  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array ensures the effect runs only on mount and unmount

  return (
    <header className="qi-header">
      <span className="branding">
        <img alt="logo" src={smallLogo} />
      </span>
      <h3 className="qi-header_title text-link text-center">
        <strong onClick={() => navigate("/")}>Superadmin Portal</strong>
      </h3>
      <div className="qi-header_nav">
        <div ref={dashboardRef} className="quick-dashboard">
          {user?.client_apps?.length > 1 && (
            <button onClick={() => setQuickDashBoard((prev) => !prev)}>
              <SvgIcon name={"grid"} />
            </button>
          )}
          {quickDashboard && (
            <div className="switch-apps">
              <Scrollbars autoHeight autoHeightMax="600px">
                <ul className="switch-apps_list">
                  <li className="switch-apps_list_item" onClick={goToDashboard}>
                    <SvgIcon name="back" wrapperClass="p-2" />
                    <strong>
                      {getLocalizedString("go_to_dashboard", "Clients and Applications")}
                    </strong>
                  </li>
                  {user.superadmin && (
                    <li
                      className={`switch-apps_list_item ${
                        selectedAppId === "superadmin" ? "active" : ""
                      }`}
                      onClick={() => {
                        initiateAppChange("superadmin");
                        setQuickDashBoard(false);
                      }}
                    >
                      <div className="application-icon-wrapper">
                        <SvgIcon name="crown" wrapperClass="crown-icon" svgClass="icon-crown" />
                      </div>
                      <span>{getLocalizedString("superadmin", "Superadmin")}</span>
                    </li>
                  )}
                  {user?.client_apps?.map((app, index) => (
                    <li
                      className={`switch-apps_list_item ${
                        selectedAppId === app.id ? "active" : ""
                      }`}
                      key={index}
                      onClick={() => {
                        initiateAppChange(app.id);
                        setQuickDashBoard(false);
                      }}
                    >
                      <div className="application-icon-wrapper">
                        {app.image_url ? (
                          <span className="application-icon_wrapper">
                            <img src={app?.image_url} className="application-icon" />
                          </span>
                        ) : app?.client?.image_url ? (
                          <span className="application-icon_wrapper">
                            <img src={app?.client?.image_url} className="application-icon" />
                          </span>
                        ) : (
                          <SvgIcon name="app-icon" wrapperClass="application-icon defult" />
                        )}
                      </div>
                      <span>{getAppName(app)}</span>
                    </li>
                  ))}
                </ul>
              </Scrollbars>
            </div>
          )}
        </div>
        <QIDropdown
          onHide={() => setDropDownSection("")}
          className="user-action-dropdown"
          toggleComponent={
            <div className="user-profile-name">
              <div className="user-profile-image-container">
                <img
                  className="user-profile-image"
                  src={
                    user?.picture?.image_extension && user?.picture?.image_data
                      ? `data:image/${user.picture.image_extension};base64,${user.picture.image_data}`
                      : userImg
                  }
                />
              </div>
              <strong>
                {user.first_name || "Unknown"} {user.last_name || ""}
              </strong>
            </div>
          }
        >
          {dropDownSection === "" && (
            <ul className="user-dropdown">
              <li className="user-dropdown_user-info" onClick={() => setDropDownSection("")}>
                <Link to="/superadmin/user-profile">
                  <strong>{`${user.first_name} ${user.last_name}`}</strong>
                </Link>
                <p>{user.email || ""}</p>
              </li>

              {/* <li className="user-dropdown_link">
                <a
                  className="user-dropdown_link_item clickable"
                  onClick={() => setDropDownSection("application-switch")}
                >
                  <SvgIcon name="switch" />
                  <span>Switch Applications</span>
                </a>
              </li> */}

              <li className="user-dropdown_link" onClick={() => setDropDownSection("")}>
                <Link className="user-dropdown_link_item" to="/superadmin/user-profile">
                  <SvgIcon name="user" />
                  <span>My Profile</span>
                </Link>
              </li>

              <li className="user-dropdown_link">
                <a className="user-dropdown_link_item clickable" onClick={logOut}>
                  <SvgIcon name="log-out" />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
          )}
          {dropDownSection === "application-switch" && (
            <Scrollbars autoHeight autoHeightMax="600px">
              <ul className="application-list">
                <li className="user-dropdown_link " onClick={() => setDropDownSection("")}>
                  <SvgIcon name="back" wrapperClass="p-2" />
                  <strong>Switch Application</strong>
                </li>
                {user.superadmin && (
                  <li
                    className={`user-dropdown_link  ${
                      selectedAppId === "superadmin" ? "active" : ""
                    }`}
                    onClick={() => initiateAppChange("superadmin")}
                  >
                    <div className="application-icon-wrapper">
                      <SvgIcon name="crown" wrapperClass="crown-icon" svgClass="icon-crown" />
                    </div>
                    <span>Superadmin</span>
                  </li>
                )}
                {user?.client_apps?.map((app, index) => (
                  <li
                    className={`user-dropdown_link  ${selectedAppId === app.id ? "active" : ""}`}
                    key={index}
                    onClick={() => initiateAppChange(app.id)}
                  >
                    <div className="application-icon-wrapper">
                      {app.image_url ? (
                        <span className="application-icon_wrapper">
                          <img src={app.image_url} className="application-icon" />
                        </span>
                      ) : (
                        <SvgIcon name="app-icon" wrapperClass="application-icon defult" />
                      )}
                    </div>
                    <span>{getAppName(app)}</span>
                  </li>
                ))}
              </ul>
            </Scrollbars>
          )}
        </QIDropdown>
      </div>
      <QIModal show={appToBeSelected ? true : false} scrollable={true} className="confirm">
        <QIModalBody>
          <h2 className="page-title mb-4 text-center">Confirmation Alert!</h2>
          <div className="confirmation-message">
            <span>
              <SvgIcon name="alert" className="confirmation-message_icon" />
            </span>
            <p>
              You are about to switch application. Please save your existing work (if any) and
              confirm.
            </p>
            <p>Are you sure?</p>
            {(selectClientAppError || selectClientAppError) && (
              <p className="text-red-600">Something went wrong, Unable to switch application</p>
            )}
          </div>
        </QIModalBody>
        <QIModalFooter>
          <QIButton
            onClick={() => setAppToBeSelected("")}
            className="qi-btn secondary md"
            variant="secondary"
            size="sm"
          >
            No
          </QIButton>
          <QIButton
            onClick={switchApplication}
            size="sm"
            variant="danger"
            className="qi-btn primary md"
          >
            Yes
          </QIButton>
        </QIModalFooter>
      </QIModal>
    </header>
  );
};
