import React, { useEffect, useState } from "react";
import moment from "moment";
import { useFormik } from "formik";
import {
  useCreateDeviceCommandMutation,
  useGetDeviceCommandsQuery,
  useGetDeviceQuery,
} from "../services";
import * as Components from "../../../components";
import { Terminal } from "./terminal";

const CommandStatus = ({ deviceId }) => {
  const { data: deviceCommands, refetch } = useGetDeviceCommandsQuery({
    device_id: deviceId,
    params: { order_by: "id", order_dir: "desc", page: 0, per_page: 20 },
  });

  const tableHeaders = [
    {
      label: "ID",
      key: "id",
    },
    {
      label: "Status",
      key: "status",
    },
    {
      label: "Type",
      key: "command_type",
    },
    {
      label: "Mode",
      key: "delivery_mode",
    },
    {
      label: "Created On",
      type: "component",
      component: ({ data }) => {
        let time = moment.unix(data.created_at).format("DD-MM-YYYY HH:mm:ss A");
        return <span title={time}>{time}</span>;
      },
    },
    {
      label: "Created by",
      key: "created_by",
    },
    {
      label: "Delivered On",
      type: "component",
      component: ({ data }) => {
        let time =
          data.delivered_on === null
            ? ""
            : moment.unix(data.delivered_on).format("DD-MM-YYYY HH:mm:ss A");
        return <span title={time}>{time}</span>;
      },
    },
    {
      label: "Command",
      key: "command",
    },
  ];

  useEffect(() => {
    const caller = setInterval(() => {
      refetch();
    }, 2000);
    return () => {
      clearInterval(caller);
    };
  });

  return (
    <Components.QITable
      headers={tableHeaders}
      data={deviceCommands?.data || []}
      className="command-sending-status"
      maxHeight="calc(100vh - 560px)"
    />
  );
};

const Command = ({ deviceId, isLockable, isResettable, deviceCountryCode }) => {
  const [initialValues] = useState({
    delivery_mode: "",
    command_type: "",
    command: "",
  });

  const { data: deviceData } = useGetDeviceQuery({ id: deviceId });

  const [sendCommand, { isSuccess }] = useCreateDeviceCommandMutation();

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const handleFormSubmit = (formData) => {
    sendCommand({
      device_id: deviceId,
      formData,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      formik.setValues(initialValues);
    }
  }, [isSuccess]);

  const commandTypes = [
    {
      label: "Configuration and Control (RAW)",
      key: "raw",
    },
  ];

  const lockCommands = [
    {
      label: "Remote Lock",
      key: "lock",
    },
    {
      label: "Remote Unlock",
      key: "unlock",
    },
  ];

  const resetCommands = [
    {
      label: "Reset",
      key: "reset",
    },
  ];

  const getDeliveryModes = () => {
    let modes = [];
    if (deviceCountryCode === "+65") {
      modes = ["TCP"];
    } else {
      modes = ["TCP", "SMS"];
    }
    return modes.map((mode) => (
      <li key={mode} value={mode.toLocaleLowerCase()}>
        {mode}
      </li>
    ));
  };

  return (
    <form
      className="command-sending-form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="command-sending-form_filed-group">
        <Components.QICustomSelect
          label="Command Type"
          className="command-type"
          {...formik.getFieldProps("command_type")}
          onChange={(value) => {
            value !== "raw" && formik.setFieldValue("command", "");
            (value === "lock" || value === "unlock") &&
              formik.setFieldValue("delivery_mode", "tcp");
            value === "raw" && formik.setFieldValue("delivery_mode", "");
            formik.setFieldValue("command_type", value);
            value === "reset" && formik.setFieldValue("delivery_mode", "tcp");
          }}
        >
          {commandTypes.map((mode) => (
            <li key={mode.key} value={mode.key}>
              {mode.label}
            </li>
          ))}
          {isLockable && (
            <>
              {lockCommands.map((mode) => (
                <li key={mode.key} value={mode.key}>
                  {mode.label}
                </li>
              ))}
            </>
          )}
          {isResettable && (
            <>
              {resetCommands.map((mode) => (
                <li key={mode.key} value={mode.key}>
                  {mode.label}
                </li>
              ))}
            </>
          )}
        </Components.QICustomSelect>

        <Components.QICustomSelect
          label="Delivery Mode"
          className="delivery-mode"
          {...formik.getFieldProps("delivery_mode")}
          onChange={(value) => formik.setFieldValue("delivery_mode", value)}
        >
          {getDeliveryModes()}
        </Components.QICustomSelect>

        <Components.QIInput
          label="Command"
          className="raw-command"
          {...formik.getFieldProps("command")}
          disabled={formik.values.command_type !== "raw"}
        />

        <Components.QIButton
          className="qi-btn  qi-btn qi-btn primary md"
          onClick={formik.handleSubmit}
        >
          Send
        </Components.QIButton>
      </div>
    </form>
  );
};

export const CommandSending = ({ deviceData = null, onClose }) => {
  const [showCommandStatus, setShowCommandStatus] = useState(false);

  return (
    <Components.QIModal show={true} onHide={onClose} size="xl-large" backdrop={false}>
      <Components.QIModalHeader onHide={onClose}>
        <h5>IMEI({deviceData?.device_id})</h5>
      </Components.QIModalHeader>

      <Components.QIModalBody className="terminal">
        <div>
          {deviceData.device_type === "tracker" && (
            <section>
              <div className="flex">
                <span
                  className={`modal_tab ${!showCommandStatus && "active"}`}
                  onClick={() => setShowCommandStatus(false)}
                >
                  Command Sending
                </span>

                <span
                  className={`modal_tab ${showCommandStatus && "active"}`}
                  onClick={() => setShowCommandStatus(true)}
                >
                  Command Status
                </span>
              </div>

              <div className={`modal_tab-content ${showCommandStatus ? "command-status" : ""} `}>
                <div className={`${!showCommandStatus ? "hidden" : ""}`}>
                  <CommandStatus deviceId={deviceData?.id} />
                </div>
                <div className={`${showCommandStatus ? "hidden" : ""}`}>
                  <Command
                    deviceId={deviceData?.id}
                    isLockable={deviceData.is_lockable}
                    isResettable={deviceData.is_resettable}
                    deviceCountryCode={deviceData?.country_code}
                  />
                  <div>
                    <h3 className="terminal-title">Terminal</h3>
                    <div>
                      <Terminal imei={deviceData?.device_id} show={true} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {deviceData.device_type !== "tracker" && (
            <div>
              <h3 className="terminal-title">Terminal</h3>
              <div>
                <Terminal imei={deviceData?.device_id} show={true} />
              </div>
            </div>
          )}
        </div>
      </Components.QIModalBody>
    </Components.QIModal>
  );
};
