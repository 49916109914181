import React, { useState, useEffect } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { MPSharedTable, MPSharedHeader, MPSharedDeleteModal } from "../Shared";
import { VClassForm } from "./form";
import { useGetVClassesQuery, useDeleteVClassMutation } from "../services";
import { useDelete } from "../../../hooks";
import { getLocalizedString } from "../../../shared/translation";

export const VClassesContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    error,
    isFetching,
    isSuccess,
  } = useGetVClassesQuery({
    page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
    q,
  });

  const {
    deleteItem: deleteVClass,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteVClassMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);

  const onEdit = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const onDelete = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const onCreate = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const headers = [
    {
      label: getLocalizedString("name", "Name"),
      key: "name",
      className: "name",
    },
    {
      label: getLocalizedString("added_on", "Added On"),
      key: "created_at",
      className: "date",
    },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((item) => item.id === id);
    if (index >= 0) {
      name = data?.data[index]?.name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName="vehicle"
          heading={getLocalizedString("manage_vehicles", "Manage Vehicles")}
          createNewHandler={onCreate}
          resourceName="Vehicle Classes"
          handleSearchKey={setSimpleSearch}
          simpleSearchPlaceholder={getLocalizedString("search_by_name", "Search by Name")}
        />

        <MPSharedTable
          resourceName="Vehicle Classes"
          data={data.data || []}
          error={error}
          isLoading={isFetching}
          isSuccess={isSuccess}
          headers={headers}
          onDelete={onDelete}
          onEdit={onEdit}
          pagination={{ ...pagination, count: data.total_count }}
          className="vehicle-classes"
          auditResource="VehicleClass"
          auditKey="name"
        />
      </article>

      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={getLocalizedString("vehicle_class", "Vehicle class")}
        error={deleteErrorMsg}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        itemName={getDeleteItemName(idToDelete)}
        onDelete={() => deleteVClass({ id: idToDelete })}
      />
      {showForm && <VClassForm idToEdit={idToEdit} onClose={setShowForm} />}
    </>
  );
};
