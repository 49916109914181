import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useGetDashBoardQuery } from "../services";
import { NativeSvgIcon } from "./../Shared/";
import { getLocalizedString } from "../../../shared/translation";

export const MPDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const vehicleSegmentLinks = [
    {
      label: getLocalizedString("vehicles", "Vehicles"),
      key: "vehicles_count",
      url: "vehicles/list",
    },
    {
      label: getLocalizedString("specifications", "Specifications"),
      key: "vehicle_models_count",
      url: "vehicles/specifications",
    },
    {
      label: getLocalizedString("variants", "Variants"),
      key: "vehicle_manufacturer_model_variants_count",
      url: "vehicles/variants",
    },
    {
      label: getLocalizedString("models", "Models"),
      key: "vehicle_manufacturer_models_count",
      url: "vehicles/manufacturers_models",
    },
    {
      label: getLocalizedString("manufacturers", "Manufacturers"),
      key: "vehicle_manufacturers_count",
      url: "vehicles/manufacturers",
    },
    {
      label: getLocalizedString("classes", "Classes"),
      key: "vehicle_classes_count",
      url: "vehicles/classes",
    },
    {
      label: getLocalizedString("types", "Types"),
      key: "vehicle_types_count",
      url: "vehicles/types",
    },
  ];

  const deviceSegmentLinks = [
    {
      label: getLocalizedString("devices", "Devices"),
      key: "devices_count",
      url: "devices",
    },
    {
      label: getLocalizedString("models", "Models"),
      key: "device_models_count",
      url: "devices/models",
    },
  ];

  const groupSegmentLinks = [
    {
      label: getLocalizedString("vehicles", "Vehicles"),
      key: "vehicle_groups_count",
      url: "groups/vehicle",
    },
    {
      label: getLocalizedString("devices", "Devices"),
      key: "device_groups_count",
      url: "groups/device",
    },
    {
      label: getLocalizedString("users", "Users"),
      key: "user_groups_count",
      url: "groups/user",
    },
  ];

  const otherLinks = [
    {
      label: getLocalizedString("users", "Users"),
      key: "users_count",
      url: "users_roles/users",
      icon: "user",
    },
    {
      label: getLocalizedString("roles", "Roles"),
      key: "client_app_roles_count",
      url: "users_roles/roles",
      icon: "role",
    },
  ];

  let segmentIcons = {
    devices: "device",
    vehicles: "vehicle",
    others: "group",
  };

  const { data = {} } = useGetDashBoardQuery();

  const segment = (className, title, path, links) => {
    return (
      <section className={className}>
        <h3
          className={`applications-oparations_title ${className && "clickable"}`}
          onClick={() => {
            className && navigate(`/management_portal/${path}`);
          }}
        >
          <NativeSvgIcon name={segmentIcons[className]} />
          {title}
        </h3>

        <ul className="applications-oparations_list">
          {links.map((item, index) => (
            <li className="applications-oparations_list_item" key={index}>
              <NavLink
                className="applications-oparations_list_link"
                to={`/management_portal/${item.url || ""}`}
              >
                <span className="applications-oparations_list_link_label">{item.label}</span>
                <strong className="applications-oparations_list_link_count">
                  {data[item.key] || 0}
                </strong>
              </NavLink>
            </li>
          ))}
        </ul>
      </section>
    );
  };

  return (
    <article className="application-summary">
      <header className="application-summary_header">
        <h4 className="application-summary_header_title">
          <NativeSvgIcon name="dashboard" />
          <span>{getLocalizedString("dashboard", "Dashboard")}</span>
        </h4>
      </header>

      <section className="applications-oparations">
        {segment(
          "devices",
          getLocalizedString("manage_devices", "Manage Devices"),
          "devices",
          deviceSegmentLinks
        )}
        {segment(
          "vehicles",
          getLocalizedString("manage_vehicles", "Manage Vehicles"),
          "vehicles",
          vehicleSegmentLinks
        )}
        {segment(
          "others",
          getLocalizedString("manage_groups", "Manage Groups"),
          "groups/vehicle",
          groupSegmentLinks
        )}

        <div className="dashboard-misc-block flex w-1/3">
          {otherLinks.map((link) => (
            <section className="dashboard-misc-block_item" key={link.label}>
              <h3 className={`applications-oparations_title `}>
                <NavLink
                  className="applications-oparations_list_link"
                  to={`/management_portal/${link.url}`}
                >
                  <NativeSvgIcon name={link.icon} />
                </NavLink>
              </h3>

              <ul className="applications-oparations_list">
                <li className="applications-oparations_list_item">
                  <NavLink
                    className="applications-oparations_list_link"
                    to={`/management_portal/${link.url || ""}`}
                  >
                    <span className="applications-oparations_list_link_label">{link.label}</span>
                    <strong className="applications-oparations_list_link_count">
                      {data[link.key] || 0}
                    </strong>
                  </NavLink>
                </li>
              </ul>
            </section>
          ))}
        </div>
      </section>
    </article>
  );
};
