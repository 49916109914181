import React, { useState } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared/";
import { useGetVModelsQuery, useDeleteVModelMutation } from "../services";
import { VModelForm } from "./Form";
import { useQuery, useDelete } from "../../../hooks";
import { VehicleModelAdvanceSearch } from "./advanceSearch";

export const VModelsContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, advanceSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_client_name_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
  };

  let query = useQuery();
  let filterLabel = "";
  const filters = [{ label: "Obd Code", key: "obd_code_id" }];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterLabel = `${filter.label} : ${query.get("name")}`;
    }
  });

  const {
    data = { data: [], total_count: 0 },

    isFetching,
    isSuccess,
    error,
  } = useGetVModelsQuery({ ...queryParams, order_by: "created_at", order_dir: "desc", q });

  const {
    deleteItem: deleteVModel,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteVModelMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const headers = [
    {
      label: "Client",
      className: "client-name",
      nestedValue: true,
      getNestedValue: ({ client_name }) => client_name || "All",
    },
    { label: "Name", key: "name", className: "name" },
    { label: "Manufacturer", key: "vehicle_manufacturer_name", className: "manufacturers" },
    { label: "Model", key: "vehicle_manufacturer_model_name", className: "model" },
    {
      label: "Variant",
      key: "vehicle_manufacturer_model_variant_name",
      className: "varient",
    },
    { label: "Capacity", key: "capacity", className: "capacity text-center" },
    { label: "Engine Capacity", key: "engine_capacity", className: "engine" },
    { label: "Year", key: "year", className: "year" },
    {
      label: "Types",
      key: "vehicle_type_name",
      className: "vehicle-type-name",
    },
    {
      label: "Classes",
      className: "vehicle-class-name",
      key: "vehicle_class_name",
    },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = `${data?.data[index].name}`;
    }
    return name;
  };

  return (
    <>
      <article className="main-container specifications">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          groupName="vehicle"
          heading="Manage Vehicles"
          handleSearchKey={(value) => setSimpleSearch(value)}
          handleAdvanceSearchKeys={(value) => setAdvanceSearch(null)}
          simpleSearchPlaceHolder="Search by Name, Client"
          filterText={filterLabel && `Filtered by ${filterLabel}`}
        >
          <VehicleModelAdvanceSearch
            onSearch={(value) => setAdvanceSearch(value)}
            activeSearch={advanceSearch}
          />
        </AdminSharedHeader>

        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          onDelete={deleteClickHandler}
          className="vehicle-specifications"
          auditResource="VehicleModel"
          auditKey="name"
        />
      </article>

      <AdminSharedDeleteModal
        show={showDeleteWarning}
        error={deleteErrorMsg}
        resourceName="Specification"
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteVModel({ id: idToDelete })}
      />
      {showForm && <VModelForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}
    </>
  );
};
