import { createApi } from "@reduxjs/toolkit/query/react";
import {
  getQuery,
  getCollectionQuery,
  createMutation,
  updateMutation,
  deleteMutation,
  baseQueryAuth,
} from "../../reduxStore/helper";

export const adminApi = createApi({
  name: "adminApi",
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryAuth(process.env.REACT_APP_ADMIN_API_URL),
  tagTypes: ["clients", "backends", "geocoders", "users", "device_manufacturers"],
  endpoints: (builder) => ({
    getDashBoard: getCollectionQuery(builder, "dashboard"),

    getApp: getQuery(builder, "apps", "id"),
    getApps: getCollectionQuery(builder, "apps"),
    createApp: createMutation(builder, "apps"),
    updateApp: updateMutation(builder, "apps"),
    deleteApp: deleteMutation(builder, "apps"),

    getAdditionalFeatures: getCollectionQuery(builder, "additional_features"),

    getClient: getQuery(builder, "clients", "id"),
    getClients: getCollectionQuery(builder, "clients"),
    createClient: createMutation(builder, "clients"),
    updateClient: updateMutation(builder, "clients"),
    deleteClient: deleteMutation(builder, "clients"),

    getGeoCoders: getCollectionQuery(builder, "geocoders"),
    getGeoCoder: getQuery(builder, "geocoders", "id"),
    createGeoCoder: createMutation(builder, "geocoders"),
    updateGeoCoder: updateMutation(builder, "geocoders"),
    deleteGeoCoder: deleteMutation(builder, "geocoders"),

    getGeolocations: getCollectionQuery(builder, "geolocation_plans"),
    getGeolocation: getQuery(builder, "geolocation_plans", "id"),
    createGeolocation: createMutation(builder, "geolocation_plans"),
    updateGeolocation: updateMutation(builder, "geolocation_plans"),
    deleteGeolocation: deleteMutation(builder, "geolocation_plans"),

    getBackend: getQuery(builder, "backends", "id"),
    getBackends: getCollectionQuery(builder, "backends"),
    getBackendDropdown: getCollectionQuery(builder, "backends/dropdown"),
    createBackend: createMutation(builder, "backends"),
    updateBackend: updateMutation(builder, "backends"),
    deleteBackend: deleteMutation(builder, "backends"),

    getMSP: getQuery(builder, "mobile_service_providers", "id"),
    getMSPs: getCollectionQuery(builder, "mobile_service_providers"),
    createMSP: createMutation(builder, "mobile_service_providers"),
    deleteMSP: deleteMutation(builder, "mobile_service_providers"),
    updateMSP: updateMutation(builder, "mobile_service_providers"),

    getLocalization: getQuery(builder, "localizations", "id"),
    getLocalizations: getCollectionQuery(builder, "localizations"),
    createLocalization: createMutation(builder, "localizations"),
    deleteLocalization: deleteMutation(builder, "localizations"),
    updateLocalization: updateMutation(builder, "localizations"),

    getLanguages: getCollectionQuery(builder, "locale"),
    getApplicationVariants: getCollectionQuery(builder, "application_variants"),

    createLocalizationUpload: builder.mutation({
      query: (queryParams) => {
        return {
          url: `localizations/upload`,
          method: "post",
          body: queryParams.formData,
        };
      },
    }),

    getLocalizationDownload: getCollectionQuery(builder, "localizations/download"),

    getUsers: getCollectionQuery(builder, "users"),
    getUser: getQuery(builder, "users", "id"),
    createUser: createMutation(builder, "users"),
    updateUser: updateMutation(builder, "users"),
    deleteUser: deleteMutation(builder, "users"),
    updateUserProfile: updateMutation(builder, "profiles"),

    getDMans: getCollectionQuery(builder, "device_manufacturers"),
    getDMan: getQuery(builder, "device_manufacturers", "id"),
    createDMan: createMutation(builder, "device_manufacturers"),
    updateDMan: updateMutation(builder, "device_manufacturers"),
    deleteDMan: deleteMutation(builder, "device_manufacturers"),

    getDModels: getCollectionQuery(builder, "device_models"),
    getDModel: getQuery(builder, "device_models", "id"),
    createDModel: createMutation(builder, "device_models"),
    updateDModel: updateMutation(builder, "device_models"),
    deleteDModel: deleteMutation(builder, "device_models"),

    getDModelControlSignals: getCollectionQuery(builder, "device_model_control_signals"),
    getDModelControlSignal: getQuery(builder, "device_model_control_signals", "id"),
    createDModelControlSignal: createMutation(builder, "device_model_control_signals"),
    updateDModelControlSignal: updateMutation(builder, "device_model_control_signals"),
    deleteDModelControlSignal: deleteMutation(builder, "device_model_control_signals"),

    getDModelVariables: getCollectionQuery(builder, "device_model_variables"),
    getDModelVariable: getQuery(builder, "device_model_variables", "id"),
    createDModelVariable: createMutation(builder, "device_model_variables"),
    updateDModelVariable: updateMutation(builder, "device_model_variables"),
    deleteDModelVariable: deleteMutation(builder, "device_model_variables"),

    getVTypes: getCollectionQuery(builder, "vehicle_types"),
    getVType: getQuery(builder, "vehicle_types", "id"),
    createVType: createMutation(builder, "vehicle_types"),
    updateVType: updateMutation(builder, "vehicle_types"),
    deleteVType: deleteMutation(builder, "vehicle_types"),

    getVClasses: getCollectionQuery(builder, "vehicle_classes"),
    getVClass: getQuery(builder, "vehicle_classes", "id"),
    createVClass: createMutation(builder, "vehicle_classes"),
    updateVClass: updateMutation(builder, "vehicle_classes"),
    deleteVClass: deleteMutation(builder, "vehicle_classes"),

    getVMans: getCollectionQuery(builder, "vehicle_manufacturers"),
    getVMan: getQuery(builder, "vehicle_manufacturers", "id"),
    createVMan: createMutation(builder, "vehicle_manufacturers"),
    updateVMan: updateMutation(builder, "vehicle_manufacturers"),
    deleteVMan: deleteMutation(builder, "vehicle_manufacturers"),

    getVManModels: getCollectionQuery(builder, "vehicle_manufacturer_models"),
    getVManModel: getQuery(builder, "vehicle_manufacturer_models", "id"),
    createVManModel: createMutation(builder, "vehicle_manufacturer_models"),
    updateVManModel: updateMutation(builder, "vehicle_manufacturer_models"),
    deleteVManModel: deleteMutation(builder, "vehicle_manufacturer_models"),

    getVManModelVariants: getCollectionQuery(builder, "vehicle_manufacturer_model_variants"),
    getVManModelVariant: getQuery(builder, "vehicle_manufacturer_model_variants", "id"),
    createVManModelVariant: createMutation(builder, "vehicle_manufacturer_model_variants"),
    updateVManModelVariant: updateMutation(builder, "vehicle_manufacturer_model_variants"),
    deleteVManModelVariant: deleteMutation(builder, "vehicle_manufacturer_model_variants"),

    getVModels: getCollectionQuery(builder, "vehicle_models"),
    getVModel: getQuery(builder, "vehicle_models", "id"),
    createVModel: createMutation(builder, "vehicle_models"),
    updateVModel: updateMutation(builder, "vehicle_models"),
    deleteVModel: deleteMutation(builder, "vehicle_models"),

    getVariableTypes: getCollectionQuery(builder, "variable_types"),
    getVariableType: getQuery(builder, "variable_types", "id"),
    createVariableType: createMutation(builder, "variable_types"),
    updateVariableType: updateMutation(builder, "variable_types"),
    deleteVariableType: deleteMutation(builder, "variable_types"),

    getSections: getCollectionQuery(builder, "sections"),
    getUnits: getCollectionQuery(builder, "units"),
    createVariables: createMutation(builder, "variables"),
    updateVariables: updateMutation(builder, "variables"),
    getVariable: getQuery(builder, "variables", "id"),
    getVariables: getCollectionQuery(builder, "variables"),
    deleteVariable: deleteMutation(builder, "variables"),

    getObdCodes: getCollectionQuery(builder, "obd_codes"),
    getObdCode: getQuery(builder, "obd_codes", "id"),
    getObdCodesDataTypes: getQuery(builder, "obd_codes/data_types"),
    createObdCode: createMutation(builder, "obd_codes"),
    updateObdCode: updateMutation(builder, "obd_codes"),
    deleteObdCode: deleteMutation(builder, "obd_codes"),

    getTargetSections: getCollectionQuery(builder, "target_sections"),
    getTargetSection: getQuery(builder, "target_sections", "id"),
    createTargetSection: createMutation(builder, "target_sections"),
    updateTargetSection: updateMutation(builder, "target_sections"),
    deleteTargetSection: deleteMutation(builder, "target_sections"),

    getTargetVariables: getCollectionQuery(builder, "target_variables"),
    getTargetVariable: getQuery(builder, "target_variables", "id"),
    createTargetVariable: createMutation(builder, "target_variables"),
    updateTargetVariable: updateMutation(builder, "target_variables"),
    deleteTargetVariable: deleteMutation(builder, "target_variables"),

    getBatchCommands: getCollectionQuery(builder, "batch_commands"),
    getBatchCommand: getQuery(builder, "batch_commands", "id"),
    createBatchCommands: createMutation(builder, "batch_commands"),
    updateBatchCommands: updateMutation(builder, "batch_commands"),

    getDevices: getCollectionQuery(builder, "devices"),
    getDevice: getQuery(builder, "devices", "id"),
    createDevice: createMutation(builder, "devices"),
    updateDevice: updateMutation(builder, "devices"),
    deleteDevice: deleteMutation(builder, "devices"),

    getVehicles: getCollectionQuery(builder, "vehicles"),
    getVehicle: getQuery(builder, "vehicles", "id"),
    createVehicle: createMutation(builder, "vehicles"),
    updateVehicle: updateMutation(builder, "vehicles"),
    deleteVehicle: deleteMutation(builder, "vehicles"),

    getVariableMappings: getCollectionQuery(builder, "variable_mappings"),
    getVariableMapping: getQuery(builder, "variable_mappings", "id"),
    createVariableMapping: createMutation(builder, "variable_mappings"),
    updateVariableMapping: updateMutation(builder, "variable_mappings"),
    deleteVariableMapping: deleteMutation(builder, "variable_mappings"),

    getDeviceModelVariables: getCollectionQuery(builder, "device_model_variables"),

    getBackendTypes: getCollectionQuery(builder, "backends/transport_types"),

    getCountries: getCollectionQuery(builder, "/countries"),

    createDeviceCommand: builder.mutation({
      query: (queryParams) => {
        return {
          url: `devices/${queryParams.device_id}/device_commands`,
          method: "post",
          body: queryParams.formData,
        };
      },
    }),

    updateVariablesDeviceModels: builder.mutation({
      query: (queryParams) => {
        return {
          url: `device_models/${queryParams.device_model_id}/add_variables`,
          method: "put",
          body: queryParams.formData,
        };
      },
    }),

    deleteVariablesDeviceModels: builder.mutation({
      query: (queryParams) => {
        return {
          url: `device_models/${queryParams.device_model_id}/remove_variables`,
          method: "put",
          body: queryParams.formData,
        };
      },
    }),

    getDeviceCommands: builder.query({
      query: (queryParams) => ({
        url: `devices/${queryParams.device_id}/device_commands`,
        method: "get",
        params: queryParams.params,
      }),
      keepUnusedDataFor: 2,
    }),

    resetPassword: builder.mutation({
      query: (queryParams) => {
        return {
          url: `users/${queryParams.id}/update_password`,
          method: "put",
          body: queryParams.formData,
        };
      },
    }),

    getAuditableResourceType: getCollectionQuery(builder, "auditable_events/resource_types"),
    getAuditableEvent: getQuery(builder, "auditable_events", "id"),
  }),
});

export const internalService = createApi({
  reducerPath: "internalApi",
  name: "internalApi",
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryAuth(process.env.REACT_APP_ADMIN_API_URL),
  endpoints: (builder) => ({
    getAuditableEvents: getCollectionQuery(builder, "auditable_events"),
  }),
});

export const { useGetAuditableEventsQuery } = internalService;

export const {
  useGetDashBoardQuery,

  useGetAppQuery,
  useGetAppsQuery,
  useCreateAppMutation,
  useDeleteAppMutation,
  useUpdateAppMutation,

  useGetAdditionalFeaturesQuery,

  useGetClientQuery,
  useGetClientsQuery,
  useCreateClientMutation,
  useDeleteClientMutation,
  useUpdateClientMutation,

  useGetGeoCodersQuery,
  useGetGeoCoderQuery,
  useUpdateGeoCoderMutation,
  useCreateGeoCoderMutation,
  useDeleteGeoCoderMutation,

  useGetGeolocationsQuery,
  useGetGeolocationQuery,
  useUpdateGeolocationMutation,
  useCreateGeolocationMutation,
  useDeleteGeolocationMutation,

  useGetBackendQuery,
  useGetBackendsQuery,
  useGetBackendDropdownQuery,
  useCreateBackendMutation,
  useDeleteBackendMutation,
  useUpdateBackendMutation,

  useCreateMSPMutation,
  useGetMSPQuery,
  useGetMSPsQuery,
  useDeleteMSPMutation,
  useUpdateMSPMutation,

  useCreateLocalizationMutation,
  useGetLocalizationQuery,
  useGetLocalizationsQuery,
  useDeleteLocalizationMutation,
  useUpdateLocalizationMutation,

  useGetLanguagesQuery,
  useGetApplicationVariantsQuery,

  useCreateLocalizationUploadMutation,
  useGetLocalizationDownloadQuery,

  useGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdateUserProfileMutation,

  useGetDMansQuery,
  useGetDManQuery,
  useCreateDManMutation,
  useUpdateDManMutation,
  useDeleteDManMutation,

  useGetDModelsQuery,
  useGetDModelQuery,
  useCreateDModelMutation,
  useUpdateDModelMutation,
  useDeleteDModelMutation,

  useGetDModelControlSignalsQuery,
  useGetDModelControlSignalQuery,
  useCreateDModelControlSignalMutation,
  useUpdateDModelControlSignalMutation,
  useDeleteDModelControlSignalMutation,

  useGetDModelVariablesQuery,
  useGetDModelVariableQuery,
  useCreateDModelVariableMutation,
  useUpdateDModelVariableMutation,
  useDeleteDModelVariableMutation,

  useGetVMansQuery,
  useGetVManQuery,
  useCreateVManMutation,
  useUpdateVManMutation,
  useDeleteVManMutation,

  useGetVManModelsQuery,
  useGetVManModelQuery,
  useCreateVManModelMutation,
  useUpdateVManModelMutation,
  useDeleteVManModelMutation,

  useGetVManModelVariantsQuery,
  useGetVManModelVariantQuery,
  useCreateVManModelVariantMutation,
  useUpdateVManModelVariantMutation,
  useDeleteVManModelVariantMutation,

  useGetVModelsQuery,
  useGetVModelQuery,
  useCreateVModelMutation,
  useUpdateVModelMutation,
  useDeleteVModelMutation,

  useGetVTypesQuery,
  useGetVTypeQuery,
  useCreateVTypeMutation,
  useUpdateVTypeMutation,
  useDeleteVTypeMutation,

  useGetVClassesQuery,
  useGetVClassQuery,
  useCreateVClassMutation,
  useUpdateVClassMutation,
  useDeleteVClassMutation,

  useGetVariableTypesQuery,
  useGetVariableTypeQuery,
  useCreateVariableTypeMutation,
  useUpdateVariableTypeMutation,
  useDeleteVariableTypeMutation,

  useGetSectionsQuery,
  useGetUnitsQuery,
  useCreateVariablesMutation,
  useUpdateVariablesMutation,
  useGetVariableQuery,
  useGetVariablesQuery,
  useDeleteVariableMutation,

  useGetObdCodesQuery,
  useGetObdCodeQuery,
  useGetObdCodesDataTypesQuery,
  useCreateObdCodeMutation,
  useUpdateObdCodeMutation,
  useDeleteObdCodeMutation,

  useGetTargetSectionQuery,
  useGetTargetSectionsQuery,
  useCreateTargetSectionMutation,
  useUpdateTargetSectionMutation,
  useDeleteTargetSectionMutation,

  useGetTargetVariableQuery,
  useGetTargetVariablesQuery,
  useCreateTargetVariableMutation,
  useUpdateTargetVariableMutation,
  useDeleteTargetVariableMutation,

  useGetBatchCommandsQuery,
  useGetBatchCommandQuery,
  useCreateBatchCommandsMutation,
  useUpdateBatchCommandsMutation,

  useGetDevicesQuery,
  useGetDeviceQuery,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,

  useGetVehiclesQuery,
  useGetVehicleQuery,
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
  useDeleteVehicleMutation,

  useGetVariableMappingsQuery,
  useGetVariableMappingQuery,
  useCreateVariableMappingMutation,
  useUpdateVariableMappingMutation,
  useDeleteVariableMappingMutation,

  useGetDeviceModelVariablesQuery,

  useGetBackendTypesQuery,
  useCreateDeviceCommandMutation,
  useGetDeviceCommandsQuery,
  useResetPasswordMutation,

  useGetAuditableEventQuery,
  useGetAuditableResourceTypeQuery,
  useGetCountriesQuery,
  useUpdateVariablesDeviceModelsMutation,
  useDeleteVariablesDeviceModelsMutation,
} = adminApi;
