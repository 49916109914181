import React from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { CommonHeader } from "./Shared";
import { SvgIcon } from "../Shared/";
import { NativeSvgIcon } from "./Shared/";

export const AdminLayout = () => {
  const location = useLocation();

  // Main navigation
  const leftPanelLinks = [
    { label: "dashboard", path: "", icon: "dashboard" },
    { label: "Manage Devices", path: "devices", icon: "device" },
    { label: "Manage Vehicles", path: "vehicles", icon: "vehicle" },
    { label: "Configurations", path: "others", icon: "undefined" },
    { label: "clients", path: "clients", icon: "client" },
    { label: "Admin Users", path: "users", icon: "user" },
    { label: "Audit Trail", path: "audit-trail", icon: "audit-log" },
  ];

  const containerClass = () => {
    return location.pathname.split("/").slice(-1).pop().replaceAll("_", "-");
  };

  return (
    <>
      <CommonHeader appName="Management Portal" />
      <div className={`admin-dashboard flex ${containerClass()}`}>
        <aside className="qi-nav-bar primary left">
          <nav className="qi-nav-bar_navigation">

          <ul className="qi-nav-bar_navigation_sub-nav">
            {leftPanelLinks.map((section, index) => (
              <li className="qi-nav-bar_navigation_sub-nav_item">
                <NavLink
                  key={index}
                  to={`/superadmin/${section.path}`}
                  className={`qi-nav-bar_navigation_sub-nav_item_link `}
                >
                  <NativeSvgIcon name={`${section.icon}`} />
                  <div className="qi-nav-bar_navigation_sub-nav_item_info">
                    <span className="">{section.label}</span>
                  </div>
                </NavLink>
                </li>
              ))}
            </ul>
          </nav>

          <div className="copyright">
            <span>&copy;</span>
            <p className="copyright_info">
              Copyright &copy; {new Date().getFullYear()} Fleet Management. All rights reserved.
            </p>
          </div>
        </aside>

        <main className="tab-content grow">
          <Outlet />
        </main>
      </div>
    </>
  );
};
