import React, { useState } from "react";
import * as Components from "../../../components";
import { MPSharedTable } from ".";
import { useGetAuditableEventsQuery } from "../services";
import { ChangeLog } from "../AuditTrail/show";
import {getLocalizedString} from "../../../shared/translation";

export const AuditTrailModal = ({ resourceType, onClose, auditId, auditKey = "name", data }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);

  const pagination = {
    page: page,
    perPage: perPage,
    onPageChange: (value) => setPage(value),
    onPerPageChange: (value) => setPerPage(value),
  };

  const queryParams = {
    page: page,
    per_page: perPage,
    resource_type: resourceType,
    resource_id: auditId,
  };

  const [changes, setChanges] = useState(null);

  //This lists the audit trail
  const {
    data: detailedData = { data: [], total_count: 0 },
    error: detailedDataError,
    isFetching: detailedDataIsFetching,
    isSuccess: detailedDataSuccess,
  } = useGetAuditableEventsQuery({
    ...queryParams,
  });

  const headers = [
    {
      label: getLocalizedString("entity", "Entity"),
      key: "resource_value",
      class: "entity",
    },
    { label: getLocalizedString("event", "Event"), key: "event_type", className: "event" },
    {
      label: getLocalizedString("updated_by", "Updated By"),
      key: "user_email",
      className: "updated-by",
    },
    {
      label: getLocalizedString("added_on", "Added On"),
      key: "created_at",
      className: "date",
    },
    { className: "expand" },
  ];

  const showAuditDetails = (changes) => {
    return (
      <ChangeLog
        idToAudit={changes?.id}
        onClose={() => setChanges(null)}
        auditForm={false}
        auditTrailModalView={true}
      />
    );
  };

  const onChange = (datum) => {
    setChanges(datum.id);
  };

  return (
    <Components.QIModal show={true} size="xl-large">
      <Components.QIModalHeader onHide={onClose}>
        <h5>{getLocalizedString("audit_trail", "Audit Trail")}</h5>
      </Components.QIModalHeader>

      <Components.QIModalBody className="audit-trail-quick-view-wrapper">
        <h3 className="log-for">
          <strong>{resourceType}</strong> - ({detailedData?.data[0]?.resource_value})
        </h3>

        <MPSharedTable
          isLoading={detailedDataIsFetching}
          pagination={{ ...pagination, count: detailedData.total_count }}
          error={detailedDataError}
          isSuccess={detailedDataSuccess}
          data={detailedData.data || []}
          headers={headers}
          className="audit-trail-quick-view"
          onChange={onChange}
          detailedView={true}
          detailedData={showAuditDetails}
        />
      </Components.QIModalBody>
    </Components.QIModal>
  );
};
