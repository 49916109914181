import React, { useState } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable, TableDropDownView } from "../Shared/";
import { useGetVehiclesQuery } from "../services";
import { VehicleForm } from "./Form";
import { VehicleAdvanceSearch } from "./advanceSearch";
import { useQuery } from "../../../hooks";

export const VehiclesContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, advanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_plate_number_cont",
    additionalSimpleSearchKeys: ["filter_device_imei_cont"],
  });

  const queryParams = {
    page: page,
    per_page: perPage,
  };

  let query = useQuery();
  let filterLabel = "";
  const filters = [
    { label: "Manfacturer", key: "vehicle_manufacturer_id" },
    { label: "Model", key: "vehicle_model_id" },
    { label: "Client", key: "client_id" },
    { label: "Obd Code", key: "obd_code_id" },
  ];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterLabel = `${filter.label} : ${query.get("name")}`;
    }
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetVehiclesQuery({
    ...queryParams,
    order_by: "vehicles.created_at",
    order_dir: "desc",
    q,
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  // const vehicleModelInfo = ({
  //   vehicle_manufacturer_name,
  //   vehicle_manufacturer_model_name,
  //   vehicle_manufacturer_model_variant_name,
  //   capacity,
  //   engine_capacity,
  //   year,
  // }) => {
  //   return `
  //     Model: ${vehicle_manufacturer_model_name},
  //     Engine Capacity: ${engine_capacity},
  //     Capacity:${capacity || "0"} seater ,
  //     Year: ${year || ""},
  //     Manufacturer: ${vehicle_manufacturer_name || "N/A"},
  //     Variant: ${vehicle_manufacturer_model_variant_name || "N/A"}
  //   `;
  // };

  const headers = [
    { label: "Client", key: "client_name", className: "client" },
    { label: "Name", key: "name", className: "name" },
    { label: "Plate Number", key: "plate_number", className: "plate-number" },
    { label: "Engine Number", key: "engine_number", className: "engine-number" },
    { label: "Chassis Number", key: "chasis_number", className: "chasis-number" },
    {
      label: "Specification",
      key: "vehicle_model_name",
      className: "model",
    },
    {
      label: "Devices",
      type: "component",
      className: "devices more",
      component: ({ data }) => {
        const deviceNames = data?.device_name_list?.split(",") || [""];
        return <TableDropDownView data={deviceNames} />;
      },
    },
    {
      label: "User",
      key: "user_name",
      className: "w-25",
    },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  const parentName = () => {
    if (queryParams.vehicle_manufacturer_id) {
      return data.data[0]?.vehicle_manufacturer_name;
    } else if (queryParams.vehicle_model_id) {
      return data.data[0]?.vehicle_model_name;
    } else if (queryParams.client_id) {
      return data.data[0]?.client_name;
    }
  };

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          groupName="vehicle"
          heading="Manage Vehicles"
          handleSearchKey={(value) => setSimpleSearch(value)}
          handleAdvanceSearchKeys={() => setAdvanceSearch(null)}
          filterText={filterLabel && `Filtered by ${filterLabel}`}
          simpleSearchPlaceHolder="Search by Name, Plate No., Device IMEI"
        >
          <VehicleAdvanceSearch
            onSearch={(value) => setAdvanceSearch(value)}
            activeSearch={advanceSearch}
          />
        </AdminSharedHeader>

        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          parentName={parentName()}
          className="vehicles"
          auditResource="Vehicle"
          auditKey="plate_number"
        />
      </article>

      {showForm && <VehicleForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}
    </>
  );
};
